@import "~@allianz/ndbx-styles/dist/patternlab.css";
@import "~@allianz/ngx-ndbx/css/ndbx-base.css";
@import '~@angular/cdk/overlay-prebuilt.css';
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: rgb(245, 245, 245) !important;
  overflow-x: hidden !important;
  padding-top: 69px !important;
  color: #414141 !important;
}
body .main {
  padding: 3em 0em;
}
body .main--whitebg {
  background: #ffffff;
}
@media (max-width: 703px) {
  body {
    padding-top: 60px;
  }
}

button {
  margin: 0px !important;
}

input {
  max-width: 100% !important;
}

p {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.punkte-einlosen--page .main .nx-grid--max-width .nx-justify-content-large-start {
  width: auto;
}
@media (max-width: 703px) {
  .punkte-einlosen--page .main .nx-grid--max-width .nx-justify-content-large-start {
    padding: 0;
    margin: 0;
  }
}
@media (max-width: 703px) {
  .punkte-einlosen--page .hero-section.hero-banner {
    min-height: 504px !important;
  }
}

@media (max-width: 703px) {
  .hero-section.hero-banner .how-it-works--hero p {
    font-size: 16px !important;
  }
}

.advantage-section {
  background-color: #ffffff;
  padding-top: 108px;
  padding-bottom: 64px;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}
@media (max-width: 703px) {
  .advantage-section {
    padding: 40px 7px 0 7px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage-section {
    padding: 40px 0px 0px 0px;
  }
}
.advantage-section .text-center {
  margin-bottom: 40px;
}
.advantage-section .advantage-section-contant--heading {
  text-align: center;
  margin: auto;
  width: 928px;
  max-width: 100%;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant--heading {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.advantage-section .advantage-section-contant--heading h2 {
  font-size: 40px;
  letter-spacing: 0.3px;
  line-height: 48px;
  margin-bottom: 52px;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant--heading h2 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 40px;
    -moz-hyphens: none;
    -ms-hyphens: none;
    -webkit-hyphens: none;
    hyphens: none;
  }
}
.advantage-section .section-contant-one {
  position: relative;
}
.advantage-section .section-contant-one .left-block {
  position: absolute;
  float: left;
  width: 448px;
  margin-top: 48px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-one .left-block {
    position: unset;
    margin-top: 15px;
  }
}
.advantage-section .section-contant-one .left-block h3 {
  width: 432px;
}
.advantage-section .section-contant-one .left-block .body-text {
  width: 432px;
  height: 528px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-one .left-block .body-text {
    height: 744px;
  }
}
.advantage-section .section-contant-one .left-block p {
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 24px;
}
.advantage-section .section-contant-one .left-block p:last-child {
  margin-bottom: 0;
}
.advantage-section .section-contant-one img {
  width: 223px;
  margin: 27px 17px 28px 0;
  float: right;
  height: 124px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-one img {
    margin: 0 !important;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-one .boader-line-box {
    margin-bottom: 0 !important;
  }
  .advantage-section .section-contant-one .boader-line-box .boader-line-six {
    height: 42px;
  }
}
.advantage-section .section-contant-one .right-block-image {
  float: right;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-one .right-block-image {
    padding-top: 58.72px;
    padding-bottom: 34.28px;
  }
}
.advantage-section .section-contant-two {
  position: relative;
}
.advantage-section .section-contant-two .boader-line-three {
  height: 149px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .boader-line-three {
    height: 42px;
  }
}
.advantage-section .section-contant-two .right-block-image {
  width: 305px;
  float: left;
}
.advantage-section .section-contant-two .right-block-image img {
  margin: 28px 0 28px 17px;
  width: 224px;
  float: left;
  height: 124px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .right-block-image img {
    width: 100%;
    margin: 0 !important;
    padding-top: 0px !important;
    height: auto;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .right-block-image {
    margin-top: 59px;
    margin-bottom: 34px;
  }
}
.advantage-section .section-contant-two .left-block {
  width: 432px;
  float: right;
  height: 356px;
  position: absolute;
  top: 245px;
  right: 0;
  margin-top: 47px;
}
@media only screen and (min-width: 704px) and (max-width: 1023px) {
  .advantage-section .section-contant-two .left-block {
    width: 399px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .left-block {
    position: unset;
    height: auto;
    margin-top: 16px;
  }
}
.advantage-section .section-contant-two .left-block h3 {
  width: 432px !important;
  max-width: 100%;
}
.advantage-section .section-contant-two .left-block .body-text {
  width: 432px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .left-block .body-text {
    height: 504px;
  }
}
.advantage-section .section-contant-two .left-block .body-text p {
  font-family: "Allianz Neo";
  letter-spacing: -0.1px;
  margin-bottom: 24px;
  margin-top: 0px;
}
.advantage-section .section-contant-two .left-block .body-text p:last-child {
  margin-bottom: 0;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .left-block .body-text p {
    max-width: 100%;
    height: auto;
    padding-right: 0;
    letter-spacing: 0;
    margin-bottom: 32px;
  }
}
.advantage-section .section-contant-two .boader-line-two {
  height: 245px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-two .boader-line-two {
    right: 0;
    width: 90%;
    height: 42px;
  }
}
.advantage-section .section-contant-three .right-block-image {
  width: 305px;
  float: right;
}
.advantage-section .section-contant-three .right-block-image img {
  width: 223px;
  margin-right: 18px;
  float: right;
  margin-top: 7px;
  margin-left: auto;
  height: 123px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .right-block-image img {
    width: 100%;
    margin: 0 !important;
    padding-top: 0 !important;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .right-block-image {
    margin-top: 58.72px;
    margin-bottom: 38.28px;
  }
}
.advantage-section .section-contant-three .left-block {
  width: 432px;
  float: left;
}
@media only screen and (min-width: 704px) and (max-width: 1023px) {
  .advantage-section .section-contant-three .left-block {
    width: 399px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .left-block p {
    letter-spacing: 0;
    line-height: 24px;
    height: auto;
    margin: 0;
    padding-right: 2px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .left-block {
    margin-top: 16px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .boader-line-four,
  .advantage-section .section-contant-three .boader-line-five {
    height: 42px;
    top: 0;
  }
}
.advantage-section .section-contant-three .boader-line-box .boader-line-four {
  height: 137px;
}
@media only screen and (min-width: 704px) and (max-width: 768px) {
  .advantage-section .section-contant-three .boader-line-box .boader-line-four {
    height: 170px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .boader-line-box .boader-line-four {
    height: 42px;
  }
}
.advantage-section .section-contant-three .boader-line-box .boader-line-five {
  height: 61.37px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-three .boader-line-box .boader-line-five {
    height: 42px;
  }
}
.advantage-section .section-contant-four {
  position: relative;
}
.advantage-section .section-contant-four .right-block-image {
  width: 305px;
  float: left;
  height: 180px;
}
.advantage-section .section-contant-four .right-block-image img {
  width: 224px;
  margin-top: 29px;
  margin-left: 16px;
  height: 124px;
  margin-bottom: 27px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-four .right-block-image img {
    width: 264px !important;
    margin: auto;
    margin-top: 69px;
    margin-bottom: 44px;
    height: 146px !important;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-four .right-block-image {
    text-align: center;
    height: auto;
  }
}
.advantage-section .section-contant-four .left-block {
  width: 432px;
  float: right;
  position: absolute;
  right: 0;
  top: 62px;
  margin-top: 47px;
}
@media only screen and (min-width: 704px) and (max-width: 1023px) {
  .advantage-section .section-contant-four .left-block {
    width: 399px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-four .left-block {
    margin-top: 16px;
    position: unset;
  }
}
.advantage-section .section-contant-four .left-block .body-text {
  height: 216px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-four .left-block .body-text {
    height: auto;
  }
}
.advantage-section .section-contant-four .left-block .body-text p {
  margin-top: 0;
  margin-bottom: 24px;
}
.advantage-section .section-contant-four .left-block .body-text p:last-child {
  margin-bottom: 0;
}
.advantage-section .section-contant-four .boader-line-two {
  height: 62px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-four .boader-line-two {
    height: 42px;
  }
}
.advantage-section .section-contant-four .boader-line-three {
  height: 173px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-four .boader-line-three {
    height: 42px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five {
    padding-top: 0;
  }
}
.advantage-section .section-contant-five .boader-line-four {
  height: 173px;
  border-radius: 0 0 36px !important;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five .boader-line-four {
    height: 42px;
  }
}
.advantage-section .section-contant-five .boader-line-five {
  height: 67px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five .boader-line-five {
    height: 42px;
  }
}
.advantage-section .section-contant-five .left-block {
  width: 432px;
  float: left;
}
@media only screen and (min-width: 704px) and (max-width: 1023px) {
  .advantage-section .section-contant-five .left-block {
    width: 399px;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five .left-block {
    margin-top: 0;
  }
}
.advantage-section .section-contant-five .boader-line-box {
  margin-bottom: 9px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five .boader-line-box {
    margin-bottom: 16px;
  }
}
.advantage-section .section-contant-five .body-text p {
  margin: 0;
}
.advantage-section .section-contant-five .right-block-image {
  width: 305px;
  float: right;
}
.advantage-section .section-contant-five .right-block-image img {
  width: 224px;
  margin-right: 15px;
  float: right;
  height: 124px;
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five .right-block-image img {
    width: 100%;
    margin: 0 !important;
    padding-top: 0 !important;
  }
}
@media (max-width: 703px) {
  .advantage-section .section-contant-five .right-block-image {
    margin-top: 58px;
    margin-bottom: 35px;
  }
}
.advantage-section .advantage-section-contant {
  max-width: 100%;
  margin: auto;
  clear: both;
  width: 737px;
}
@media (max-width: 991px) {
  .advantage-section .advantage-section-contant {
    max-width: 100%;
  }
}
.advantage-section .advantage-section-contant .left-block {
  max-width: 100%;
}
.advantage-section .advantage-section-contant .left-block h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0.2px;
  max-width: 100%;
  margin-bottom: 16px;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .left-block h3 {
    font-size: 18px;
  }
}
.advantage-section .advantage-section-contant .left-block p {
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
}
.advantage-section .advantage-section-contant .right-block-image {
  max-width: 100%;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .right-block-image {
    width: 100%;
  }
}
@media only screen and (max-width: 320px) {
  .advantage-section .advantage-section-contant .right-block-image img {
    height: 158px;
    width: 285px;
    padding: 0;
  }
}
.advantage-section .advantage-section-contant .border-line {
  box-sizing: border-box;
  height: 42px;
  border-top: 2px solid #bc975e;
  border-right: 2px solid #bc975e;
  width: 80%;
  border-radius: 0 50px 0 0;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .border-line {
    width: 100%;
  }
}
.advantage-section .advantage-section-contant .boader-line-two,
.advantage-section .advantage-section-contant .boader-line-four {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  border-right: 2px solid #bc975e;
  border-bottom: 2px solid #bc975e;
  border-radius: 0 0 32px;
  margin: auto;
  width: 198px;
  margin-right: 128px;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .boader-line-two,
  .advantage-section .advantage-section-contant .boader-line-four {
    width: 87%;
    margin-right: 0;
    height: 42px;
    right: 0;
  }
}
.advantage-section .advantage-section-contant .boader-line-three,
.advantage-section .advantage-section-contant .boader-line-five {
  box-sizing: border-box;
  width: 289px;
  max-width: 100%;
  border-left: 2px solid #bc975e;
  border-bottom: 2px solid #bc975e;
  border-radius: 0 0 0 35px;
  margin: auto;
  -webkit-transform: rotatex(180deg);
  transform: rotatex(180deg);
  margin-left: 127px;
  position: relative;
  margin-top: -2px;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .boader-line-three,
  .advantage-section .advantage-section-contant .boader-line-five {
    left: -67px;
  }
}
.advantage-section .advantage-section-contant .boader-line-three + .right-block-image,
.advantage-section .advantage-section-contant .boader-line-five + .right-block-image {
  text-align: unset;
}
.advantage-section .advantage-section-contant .boader-line-three + .right-block-image img,
.advantage-section .advantage-section-contant .boader-line-five + .right-block-image img {
  margin: 0;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .boader-line-five {
    width: 84%;
    margin-left: 0;
    left: 0 !important;
  }
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .boader-line-three {
    left: -126px;
    width: 87%;
  }
}
.advantage-section .advantage-section-contant .boader-line-six {
  box-sizing: border-box;
  height: 244px;
  width: 609px;
  max-width: 100%;
  float: right;
  border-left: 2px solid #bc975e;
  border-bottom: 2px solid #bc975e;
  border-radius: 0 0 0 30px;
  margin: auto;
  -webkit-transform: rotatex(180deg);
  transform: rotatex(180deg);
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .boader-line-six {
    top: 0;
    left: 0;
    width: 100%;
    transform: rotate(180deg);
  }
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant.section-contant-one .boader-line-box {
    transform: rotate(0deg);
  }
}
.advantage-section .advantage-section-contant .boader-line-box {
  transform: rotatey(180deg);
}
.advantage-section .advantage-section-contant .boader-line-box .boader-line-four {
  border-radius: 0 0 35px;
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant .boader-line-box .boader-line-four {
    left: 0px !important;
    width: 87%;
  }
}
@media (max-width: 703px) {
  .advantage-section .advantage-section-contant.responsive-content--change {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .advantage-section .advantage-section-contant.responsive-content--change .left-block {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .advantage-section .advantage-section-contant.responsive-content--change .right-block-image {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
@media only screen and (max-width: 320px) {
  .advantage-section .advantage-section-contant.section-contant-one .right-block-image img, .advantage-section .advantage-section-contant.section-contant-two .right-block-image img, .advantage-section .advantage-section-contant.section-contant-three .right-block-image img, .advantage-section .advantage-section-contant.section-contant-five .right-block-image img, .advantage-section .advantage-section-contant.section-contant-six .right-block-image img, .advantage-section .advantage-section-contant.section-contant-seven .right-block-image img, .advantage-section .advantage-section-contant.section-contant-eight .right-block-image img {
    height: 158px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage-section .advantage-section-contant.section-contant-four .right-block-image img {
    width: 264px;
    height: 146px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage-section .advantage-section-contant.section-contant-one .right-block-image img, .advantage-section .advantage-section-contant.section-contant-three .right-block-image img {
    width: 285px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage-section .advantage-section-contant.section-contant-two .right-block-image img, .advantage-section .advantage-section-contant.section-contant-five .right-block-image img, .advantage-section .advantage-section-contant.section-contant-six .right-block-image img, .advantage-section .advantage-section-contant.section-contant-seven .right-block-image img, .advantage-section .advantage-section-contant.section-contant-eight .right-block-image img {
    width: 286px;
  }
}

.top-question {
  clear: both;
  margin-top: 220px;
  background-color: #ffffff;
  margin-bottom: 16px;
}
.top-question .top-question--block-heading {
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: 0.3px;
  margin-bottom: 49px;
}
@media (max-width: 703px) {
  .top-question .top-question--block-heading {
    margin-bottom: 35px;
    font-size: 35px;
    line-height: 40px;
  }
}
@media (max-width: 703px) {
  .top-question {
    margin-top: 0;
  }
}
.top-question .top-question--row {
  margin-bottom: 10px;
}
@media only screen and (max-width: 799px) {
  .top-question .top-question--row {
    margin-bottom: 0px;
  }
}
.top-question .top-question--row::before, .top-question .top-question--row::after {
  display: table;
  content: " ";
  clear: both;
}
.top-question .top-question--heading {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #414141;
  letter-spacing: 0.2px;
}
.top-question .top-question--desc {
  font-size: 16px;
  line-height: 24px;
  color: #414141;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  letter-spacing: 0.03px;
}
@media (max-width: 703px) {
  .top-question .top-question--desc {
    margin-top: 8px;
  }
}
.top-question .top-question--link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #006192;
  padding-left: 4px;
  font-family: "Allianz Neo";
  letter-spacing: 1px;
  display: block;
  max-width: 176px;
  width: 100%;
}
.top-question .top-question--link span {
  margin-left: -2px;
  max-width: 115px;
  width: 100%;
  display: inline-block;
}
.top-question .top-question--link::before {
  content: "";
  background-image: url("../../assets/images/Asset 1.svg");
  height: 10px;
  width: 30px;
  background-repeat: no-repeat;
  display: inline-block;
}

.one-third {
  width: 100%;
}

.top-question-two {
  padding-left: 11px !important;
}
@media (max-width: 703px) {
  .top-question-two {
    padding-top: 9px !important;
    padding-left: 0 !important;
    margin-bottom: 10px !important;
  }
}

.top-question-three {
  padding-left: 21px !important;
}
@media (max-width: 703px) {
  .top-question-three {
    padding-left: 0 !important;
    margin-bottom: 25px !important;
  }
}

.top-question-last {
  padding-top: 0 !important;
  margin-top: -7px !important;
}
@media (max-width: 703px) {
  .top-question-last {
    padding-bottom: 0 !important;
  }
}

.column {
  padding: 15px 0;
  margin-bottom: 16px;
}
.column .question--block {
  width: 352px;
  max-width: 100%;
}

@media only screen and (min-width: 800px) {
  .one-third {
    width: 33.3333333333%;
  }
  .column {
    float: left;
  }
}
@media (max-width: 703px) {
  .shopping-benefits--page .hero-section.hero-banner {
    min-height: 448px !important;
  }
  .shopping-benefits--page .hero-section.hero-banner h1 {
    margin-bottom: 24px !important;
  }
  .shopping-benefits--page .hero-section.hero-banner p {
    height: auto;
    line-height: 28px;
  }
}
@media (max-width: 703px) {
  .shopping-benefits--page .hero-section.hero-banner .nx-grid--max-width > div {
    height: auto;
  }
}

@media (max-width: 703px) {
  .padding-mb {
    padding: 0 !important;
  }
}
.nx-modal {
  z-index: 9999999 !important;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

app-how-it-works-page .main--whitebg.main {
  padding-top: 0px;
  padding-bottom: 64px;
}
@media (max-width: 703px) {
  app-how-it-works-page .main--whitebg.main {
    padding-bottom: 40px;
  }
}
app-how-it-works-page .hero-section .nx-grid--max-width > div {
  height: 88px;
}
app-how-it-works-page .hero-section .nx-grid--max-width > div p {
  height: auto;
}

@media (max-width: 703px) {
  .box-order {
    display: flex;
    flex-flow: column;
  }
  .box-order .right-block-image {
    order: 2;
  }
  .box-order .left-block {
    order: 1;
  }
}
.kart-icon {
  width: 149px;
}

@media (max-width: 703px) {
  .cart--img {
    position: relative;
  }
}
.cart--img .discount--round {
  display: none;
}
@media (max-width: 703px) {
  .cart--img .discount--round {
    display: block;
    position: absolute;
    background: #ffffff;
    text-align: center;
    border-radius: 16px 16px 0px 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    font-size: 16px;
    font-weight: bold;
    color: #414141;
    bottom: 8px;
    right: 8px;
    width: 64px;
    height: 44px;
    line-height: 44px;
  }
}

.advantage-section-contant.section-contant-six {
  padding-top: 21.39px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-six {
    padding-top: 0;
  }
}
.advantage-section-contant.section-contant-six .boader-line-two {
  height: 74.61px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-six .boader-line-two {
    height: 42px;
  }
}
.advantage-section-contant.section-contant-six .boader-line-three {
  height: 77px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-six .boader-line-three {
    height: 42px;
  }
}
.advantage-section-contant.section-contant-six .box-order .left-block {
  height: 116px;
  margin-top: 107px;
  top: 96px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-six .box-order .left-block {
    height: auto;
    margin-top: 16px;
  }
  .advantage-section-contant.section-contant-six .box-order .left-block .body-text {
    height: auto;
  }
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-six .box-order .right-block-image {
    margin-top: 58px;
    margin-bottom: 31px;
  }
}
.advantage-section-contant.section-contant-seven {
  position: relative;
}
.advantage-section-contant.section-contant-seven .boader-line-box {
  margin-bottom: 0;
}
.advantage-section-contant.section-contant-seven .boader-line-four {
  height: 64px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-seven .boader-line-four {
    height: 42px;
  }
}
.advantage-section-contant.section-contant-seven .right-block-image img {
  margin-top: 15px;
  margin-bottom: 18.73px;
}
.advantage-section-contant.section-contant-seven .boader-line-five {
  height: 78px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-seven .boader-line-five {
    height: 42px;
  }
}
.advantage-section-contant.section-contant-seven .left-block {
  position: absolute;
  top: 64px;
  margin-top: 47px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-seven .left-block {
    position: unset;
    margin-top: 16px;
  }
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-seven .left-block .body-text {
    padding-right: 7px;
  }
}
.advantage-section-contant.section-contant-seven .left-block .body-text p {
  margin-bottom: 24px;
}
.advantage-section-contant.section-contant-seven .left-block .body-text p:last-child {
  margin-bottom: 0;
}
.advantage-section-contant.section-contant-eight .boader-line-two {
  height: 74.27px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-eight .boader-line-two {
    height: 42px;
  }
}
.advantage-section-contant.section-contant-eight .boader-line-three {
  height: 77px;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-eight .boader-line-three {
    height: 42px;
  }
}
.advantage-section-contant.section-contant-eight .box-order .left-block {
  margin-top: 12px;
  height: 156px;
  position: unset;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-eight .box-order .left-block {
    height: auto;
    margin-top: 16px;
  }
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-eight .box-order .left-block .body-text {
    height: auto;
  }
}
.advantage-section-contant.section-contant-eight .box-order .left-block .body-text p {
  margin-bottom: 16px;
}
.advantage-section-contant.section-contant-eight .box-order .left-block .body-text p:last-child {
  margin-bottom: 0;
}
.advantage-section-contant.section-contant-eight .box-order .left-block .body-text a::before {
  content: "";
  background-image: url("../../assets/images/Asset 1.svg");
  height: 10px;
  width: 30px;
  background-repeat: no-repeat;
  display: inline-block;
}
@media (max-width: 703px) {
  .advantage-section-contant.section-contant-eight .box-order .right-block-image {
    margin-top: 50px;
    margin-bottom: 59px;
  }
}

.body-text {
  max-width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
.body-text a {
  color: #006192;
  font-family: "Allianz Neo";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 24px;
}
.body-text a span {
  max-width: 209px;
  width: 100%;
  display: inline-block;
}
@media (max-width: 703px) {
  .body-text a span {
    max-width: 254px;
  }
}
.body-text a.external--link {
  display: block;
}
@media (max-width: 703px) {
  .body-text a.external--link {
    max-width: 286px;
    width: 100%;
  }
}

.faq-link--topblock {
  padding: 44px 0;
  box-shadow: inset 0 0 0 1px #d9d9d9;
  margin-top: 0;
  background-color: #ffffff;
}
@media (max-width: 991px) {
  .faq-link--topblock {
    padding: 24px 16px;
  }
}
.faq-link--topblock p {
  max-width: 928px;
  width: 100%;
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0px auto;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}
@media (max-width: 991px) {
  .faq-link--topblock p {
    text-align: left;
  }
}
.faq-link--topblock.sticky {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 118px;
  transition: all 0.5s;
}
@media (max-width: 703px) {
  .faq-link--topblock.sticky {
    margin-top: 109px;
  }
}

.faq-link--bottomblock {
  padding: 40px 0 40px 0;
}
@media (max-width: 991px) {
  .faq-link--bottomblock {
    margin-top: 0px;
    padding: 64px 16px 24px 16px;
  }
}
.faq-link--bottomblock div.footer-center-text {
  max-width: 928px;
  width: 100%;
  color: #414141;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0px auto;
  font-weight: 300;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}

#optanon-popup-bg {
  z-index: 7000 !important;
}
#optanon-popup-bg.overlay-popup-bg {
  display: block !important;
}

.hotel-flap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2f79b3;
  color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: "Open Sans" !important;
  text-align: center;
  font-weight: bold;
}
.hotel-flap.listing {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 7px;
  padding-bottom: 7px;
}
@media (max-width: 703px) {
  .hotel-flap.listing {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
  }
}
.hotel-flap.detail {
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 0;
  padding-top: 19px;
  padding-bottom: 19px;
}
@media (max-width: 703px) {
  .hotel-flap.detail {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    line-height: 14px;
  }
}
.hotel-flap.checkout-success {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 20px;
}
.hotel-flap.checkout {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 20px;
}
@media (max-width: 703px) {
  .hotel-flap.checkout {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.nx-margin-2xs {
  margin: 8px !important;
}

.nx-margin-x-2xs {
  margin: 0 8px !important;
}

.nx-margin-y-2xs {
  margin: 8px 0 !important;
}

.nx-margin-top-2xs {
  margin-top: 8px !important;
}

.nx-margin-bottom-2xs {
  margin-bottom: 8px !important;
}

.nx-margin-left-2xs {
  margin-left: 8px !important;
}

.nx-margin-right-2xs {
  margin-right: 8px !important;
}

.nx-margin-xs {
  margin: 12px !important;
}

.nx-margin-x-xs {
  margin: 0 12px !important;
}

.nx-margin-y-xs {
  margin: 12px 0 !important;
}

.nx-margin-top-xs {
  margin-top: 12px !important;
}

.nx-margin-bottom-xs {
  margin-bottom: 12px !important;
}

.nx-margin-left-xs {
  margin-left: 12px !important;
}

.nx-margin-right-xs {
  margin-right: 12px !important;
}

.nx-margin-s {
  margin: 16px !important;
}

.nx-margin-x-s {
  margin: 0 16px !important;
}

.nx-margin-y-s {
  margin: 16px 0 !important;
}

.nx-margin-top-s {
  margin-top: 16px !important;
}

.nx-margin-bottom-s {
  margin-bottom: 16px !important;
}

.nx-margin-left-s {
  margin-left: 16px !important;
}

.nx-margin-right-s {
  margin-right: 16px !important;
}

.nx-margin-m {
  margin: 24px !important;
}

.nx-margin-x-m {
  margin: 0 24px !important;
}

.nx-margin-y-m {
  margin: 24px 0 !important;
}

.nx-margin-top-m {
  margin-top: 24px !important;
}

.nx-margin-bottom-m {
  margin-bottom: 24px !important;
}

.nx-margin-left-m {
  margin-left: 24px !important;
}

.nx-margin-right-m {
  margin-right: 24px !important;
}

.nx-margin-2m {
  margin: 32px !important;
}

.nx-margin-x-2m {
  margin: 0 32px !important;
}

.nx-margin-y-2m {
  margin: 32px 0 !important;
}

.nx-margin-top-2m {
  margin-top: 32px !important;
}

.nx-margin-bottom-2m {
  margin-bottom: 32px !important;
}

.nx-margin-left-2m {
  margin-left: 32px !important;
}

.nx-margin-right-2m {
  margin-right: 32px !important;
}

.nx-margin-3m {
  margin: 40px !important;
}

.nx-margin-x-3m {
  margin: 0 40px !important;
}

.nx-margin-y-3m {
  margin: 40px 0 !important;
}

.nx-margin-top-3m {
  margin-top: 40px !important;
}

.nx-margin-bottom-3m {
  margin-bottom: 40px !important;
}

.nx-margin-left-3m {
  margin-left: 40px !important;
}

.nx-margin-right-3m {
  margin-right: 40px !important;
}

.nx-margin-4m {
  margin: 48px !important;
}

.nx-margin-x-4m {
  margin: 0 48px !important;
}

.nx-margin-y-4m {
  margin: 48px 0 !important;
}

.nx-margin-top-4m {
  margin-top: 48px !important;
}

.nx-margin-bottom-4m {
  margin-bottom: 48px !important;
}

.nx-margin-left-4m {
  margin-left: 48px !important;
}

.nx-margin-right-4m {
  margin-right: 48px !important;
}

.nx-margin-l {
  margin: 56px !important;
}

.nx-margin-x-l {
  margin: 0 56px !important;
}

.nx-margin-y-l {
  margin: 56px 0 !important;
}

.nx-margin-top-l {
  margin-top: 56px !important;
}

.nx-margin-bottom-l {
  margin-bottom: 56px !important;
}

.nx-margin-left-l {
  margin-left: 56px !important;
}

.nx-margin-right-l {
  margin-right: 56px !important;
}

.nx-margin-xl {
  margin: 64px !important;
}

.nx-margin-x-xl {
  margin: 0 64px !important;
}

.nx-margin-y-xl {
  margin: 64px 0 !important;
}

.nx-margin-top-xl {
  margin-top: 64px !important;
}

.nx-margin-bottom-xl {
  margin-bottom: 64px !important;
}

.nx-margin-left-xl {
  margin-left: 64px !important;
}

.nx-margin-right-xl {
  margin-right: 64px !important;
}

.nx-margin-2xl {
  margin: 72px !important;
}

.nx-margin-x-2xl {
  margin: 0 72px !important;
}

.nx-margin-y-2xl {
  margin: 72px 0 !important;
}

.nx-margin-top-2xl {
  margin-top: 72px !important;
}

.nx-margin-bottom-2xl {
  margin-bottom: 72px !important;
}

.nx-margin-left-2xl {
  margin-left: 72px !important;
}

.nx-margin-right-2xl {
  margin-right: 72px !important;
}

.nx-margin-3xl {
  margin: 80px !important;
}

.nx-margin-x-3xl {
  margin: 0 80px !important;
}

.nx-margin-y-3xl {
  margin: 80px 0 !important;
}

.nx-margin-top-3xl {
  margin-top: 80px !important;
}

.nx-margin-bottom-3xl {
  margin-bottom: 80px !important;
}

.nx-margin-left-3xl {
  margin-left: 80px !important;
}

.nx-margin-right-3xl {
  margin-right: 80px !important;
}

.nx-margin-4xl {
  margin: 88px !important;
}

.nx-margin-x-4xl {
  margin: 0 88px !important;
}

.nx-margin-y-4xl {
  margin: 88px 0 !important;
}

.nx-margin-top-4xl {
  margin-top: 88px !important;
}

.nx-margin-bottom-4xl {
  margin-bottom: 88px !important;
}

.nx-margin-left-4xl {
  margin-left: 88px !important;
}

.nx-margin-right-4xl {
  margin-right: 88px !important;
}

.nx-margin-5xl {
  margin: 96px !important;
}

.nx-margin-x-5xl {
  margin: 0 96px !important;
}

.nx-margin-y-5xl {
  margin: 96px 0 !important;
}

.nx-margin-top-5xl {
  margin-top: 96px !important;
}

.nx-margin-bottom-5xl {
  margin-bottom: 96px !important;
}

.nx-margin-left-5xl {
  margin-left: 96px !important;
}

.nx-margin-right-5xl {
  margin-right: 96px !important;
}

.nx-margin-top-0 {
  margin-top: 0 !important;
}

.nx-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.nx-margin-left-0 {
  margin-left: 0 !important;
}

.nx-margin-right-0 {
  margin-right: 0 !important;
}

.nx-margin-0 {
  margin: 0 !important;
}

.text-center {
  text-align: center !important;
}

@media only screen and (min-width: 704px) {
  .text-center-large {
    text-align: center !important;
  }
}

.d-block {
  display: block !important;
  width: 100%;
}

.d-inline {
  display: inline;
}

.d-none {
  display: none !important;
}

@media (max-width: 703px) {
  .main-content--row > div:nth-child(1) {
    order: 2 !important;
  }
  .main-content--row > div:nth-child(2) {
    order: 1 !important;
  }
  .main-content--row > div:nth-child(3) {
    order: 3 !important;
  }
}

@media (max-width: 991px) {
  .related-products--responsive > div {
    padding-bottom: 10px !important;
  }
}
@media (max-width: 703px) {
  .related-products--responsive > div {
    padding-bottom: 0px !important;
  }
}
@media (max-width: 703px) {
  .related-products--responsive .related-products--block:nth-child(odd) {
    padding-right: 4px;
  }
}
@media (max-width: 703px) {
  .related-products--responsive .related-products--block:nth-child(even) {
    padding-left: 4px;
  }
}
@media (max-width: 703px) {
  .related-products--responsive .related-products--block {
    margin-bottom: 16px !important;
  }
}

.media--caption,
.caption {
  padding: 8px 17px;
  font-size: 14px;
  font-weight: 600;
  border-bottom-right-radius: 16px;
  width: auto;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.media--caption.availability,
.caption.availability {
  background-color: #ffd54e;
}
.media--caption.stock,
.caption.stock {
  background-color: #ff934f;
}
.media--caption.expired,
.caption.expired {
  background-color: #dc3149;
  color: #ffffff;
}

.media--caption {
  min-height: 5%;
  padding: 8px 20px;
}

.caption {
  max-width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 703px) {
  .caption {
    max-width: 88.571428571%;
    padding: 8px !important;
  }
}
.caption.availability {
  width: 181px;
  padding-left: 16px;
}
@media (max-width: 703px) {
  .caption.availability {
    width: 124px;
  }
}

.detailed-content .description {
  margin-bottom: 104px !important;
}
@media (max-width: 703px) {
  .detailed-content .description {
    margin-bottom: 64px !important;
  }
}
.detailed-content .description h2 {
  color: #414141;
  margin-bottom: 40px;
  max-width: 928px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 703px) {
  .detailed-content .description h2 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 24px;
    max-width: 100%;
  }
}
.detailed-content .description .content {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .detailed-content .description .content {
    max-width: 736px;
    width: 100%;
  }
}
.detailed-content .description .content p {
  line-height: 24px;
  font-size: 16px !important;
  margin-bottom: 24px;
}
@media (max-width: 703px) {
  .detailed-content .description .content p {
    margin-bottom: 16px;
    max-width: 100%;
  }
}
.detailed-content .description .content p a {
  color: #006192;
  font-weight: 600;
}
.detailed-content .description .content p a:hover {
  color: #008ed6;
}
.detailed-content .description .content p:last-child {
  margin-bottom: 0;
}
.detailed-content .description .content img {
  height: 100%;
  width: 100%;
}
.detailed-content .description .content ul,
.detailed-content .description .content ol {
  padding-left: 24px;
}
.detailed-content .description .content ul li,
.detailed-content .description .content ol li {
  font-size: 16px;
  margin-bottom: 24px;
}
@media (max-width: 703px) {
  .detailed-content .description .content ul li,
  .detailed-content .description .content ol li {
    margin-bottom: 16px;
  }
}
.detailed-content .description .content ul li a,
.detailed-content .description .content ol li a {
  color: #006192;
  font-weight: 600;
}
.detailed-content .description .content ul li a:hover,
.detailed-content .description .content ol li a:hover {
  color: #008ed6;
}
.detailed-content .description .content ul li:last-child,
.detailed-content .description .content ol li:last-child {
  margin-bottom: 0;
}
.detailed-content .description .content ul {
  list-style: disc outside;
}
.detailed-content .description .content ol {
  counter-reset: allianz-list-counter;
  list-style: none;
}
.detailed-content .description .content ol li {
  margin-left: 12px;
  counter-increment: allianz-list-counter;
  position: relative;
}
.detailed-content .description .content ol li::before {
  content: counter(allianz-list-counter);
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  left: -36px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  top: 0;
  background: #827e85;
  border-radius: 50%;
  text-align: center;
}

.option-btn--modifier button {
  width: 52.759% !important;
}
@media (max-width: 991px) {
  .option-btn--modifier button {
    width: 100% !important;
  }
}
.option-btn--modifier button nx-icon {
  margin-right: 10px;
}

.link-row--modifier {
  height: 54px;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 703px) {
  .link-row--modifier {
    padding-left: 0;
  }
}
.link-row--modifier nx-icon {
  font-size: 24px;
  color: #006192;
}
@media (max-width: 703px) {
  .link-row--modifier nx-icon {
    margin-left: 0 !important;
  }
}
.link-row--modifier nx-link {
  font-size: 18px;
}

.round--button {
  border-radius: 25px !important;
  background-color: #496ebd !important;
  text-transform: none !important;
  min-height: 40px !important;
  padding: 0.028em 30px !important;
}
.round--button span {
  font-weight: normal;
  font-size: 1em;
}
.round--button span.points--val {
  font-weight: bold;
}

.nx-spinner--large {
  margin: auto;
}

.wishlist--flag {
  font-weight: 700 !important;
}

:focus {
  outline: 2px dotted #006192 !important;
  outline-offset: 4px;
}

body.using-mouse *:focus {
  outline: none !important;
}

body:not(.using-mouse) .nx-switcher__input:focus + .nx-switcher__label .nx-switcher__toggle {
  outline: 2px dotted #006192 !important;
  outline-offset: 4px;
}
body:not(.using-mouse) .slick-slide:focus {
  border: 2px dotted #006192;
}
body:not(.using-mouse) .nx-dropdown__panel-body.is-stable .nx-dropdown-item--active .nx-dropdown-results__option .nx-dropdown-results__option-label {
  outline: 2px dotted #006192 !important;
  outline-offset: 4px;
}
body:not(.using-mouse) .nx-checkbox__input:focus + .nx-checkbox__label .nx-checkbox__control {
  outline: 2px dotted #006192 !important;
  outline-offset: 4px;
}
body:not(.using-mouse) .open-lightbox:focus {
  outline: none !important;
}
body:not(.using-mouse) .open-lightbox:focus nx-icon {
  outline: 2px dotted #006192;
  outline-offset: 4px;
}
body:not(.using-mouse) .name-carousel:focus {
  outline: none !important;
}
body:not(.using-mouse) .name-carousel:focus span {
  outline: 2px dotted #006192;
  outline-offset: 4px;
}
body:not(.using-mouse) .nx-radio__input:focus + .nx-radio__label .nx-radio__circle {
  outline: 2px dotted #006192;
  outline-offset: 4px;
}
body:not(.using-mouse) .button-thumb:focus {
  outline: none !important;
  border: 2px dotted #006192;
  padding: 4px;
}
body:not(.using-mouse) nx-expansion-panel-header:focus .nx-expansion-panel__header-content {
  border: 2px dotted #006192;
  z-index: 1;
}
body:not(.using-mouse) .nx-autocomplete-option.nx-active .nx-autocomplete-option__label {
  outline: 2px solid #006192;
  outline-offset: 4px;
}

nx-icon:hover {
  color: #008ed6;
}

em {
  font-style: italic;
}

@media (max-width: 703px) {
  .carousel-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.padding-left-none {
  padding-left: 0px !important;
}

.wishlist-icon {
  cursor: pointer !important;
}

.cart-row--modifier {
  height: 55px;
}
@media (max-width: 703px) {
  .cart-row--modifier {
    height: 78px;
  }
}

.cart-row--container {
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
}

.cart-link {
  display: flex !important;
  position: relative;
  width: 181px;
}
.cart-link .cart-count {
  position: absolute;
  top: -6px;
  right: -6px;
  background: #e4003a;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 12px;
  -webkit-box-align: center;
  align-items: center;
}

.cart-icon {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  height: 24px;
  width: 24px;
  margin-left: 8px;
}
.cart-icon.empty {
  background-image: url("../images/shopping_cart-empty.svg");
}
.cart-icon.full {
  background-image: url("../images/shopping_cart-full.svg");
}

.stepper-error {
  -webkit-text-fill-color: #dc3149 !important;
}

@media only screen and (max-width: 740px) {
  .mobile-d-none {
    display: none;
  }
}

@media (max-width: 703px) {
  .margin-smaller {
    margin-bottom: 32px;
  }
}

.pointer {
  cursor: pointer !important;
}

.noPointer {
  cursor: initial !important;
  pointer-events: none !important;
  outline: none !important;
}

h1 {
  font-size: 54px;
  font-weight: 300;
}
h1 .bold-heading {
  font-weight: 700;
}

h2 {
  font-size: 40px;
  font-weight: 300;
}

h3 {
  font-weight: 300;
}
h3 .large-subheading {
  font-size: 30px;
}
h3 .medium-subheading {
  font-size: 26px;
}
h3 .small-subheading {
  font-size: 24px;
  font-weight: 600;
}

h4 {
  font-size: 20px;
  font-weight: 300;
}

.text--success {
  color: #3da556;
  font-size: 16px;
}

@media (min-width: 1096px) {
  .nx-grid--max-width {
    max-width: 1184px !important;
  }
}
.nx-card {
  background-color: #ffffff !important;
  position: relative !important;
}

.nx-card-inner {
  padding: unset !important;
}

.nx-button--primary {
  color: #ffffff !important;
}

.nx-link {
  color: #006192 !important;
}

.nx-button--secondary {
  color: #006192 !important;
}

.nx-button--secondary:hover:not([disabled]):not(:active) {
  color: #ffffff !important;
  background-color: #006192 !important;
  border-color: #006192 !important;
}

.nx-heading--subsection-small {
  color: inherit !important;
}

.nx-card .media {
  background-repeat: no-repeat !important;
  height: 163px !important;
  background-position: center !important;
  position: relative !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.nx-card.voucher--card .media {
  background-color: #ececec !important;
}

.nx-card .media .image-div {
  max-width: 220px !important;
  width: 100% !important;
  max-height: 140px !important;
  height: 100% !important;
  border-radius: 8px !important;
  background-position: center !important;
  position: relative !important;
}

.nx-card .body p {
  margin-bottom: 4px !important;
}

.nx-pagesearch {
  background: inherit !important;
}

.nx-formfield {
  color: inherit !important;
}

.nx-formfield__input-container {
  border: 1px solid inherit !important;
  border-color: inherit !important;
}

.c-input {
  color: inherit !important;
}

.nx-button--primary {
  color: #ffffff !important;
  background-color: #007ab3 !important;
}

.nx-button--primary:hover:not(:disabled):not(:active) {
  background-color: #006192 !important;
}

.navigation-row {
  margin: auto;
}

.nav-bar-sticky .nx-grid--max-width .nx-header__row .nx-header__navigation-items .nx-header__link.is-active {
  color: #006192 !important;
  border-color: #006192 !important;
}

.nav-bar-sticky .nx-grid--max-width .nx-header__row .nx-header__navigation-items .nx-header__link:hover {
  border-color: #006192 !important;
  color: #006192 !important;
}

.filter-group .nx-tab-header__item.nx-tab-header__item--active {
  display: none;
}
.filter-group .nx-button--primary {
  background-color: inherit !important;
  color: inherit !important;
}

input:focus,
.nx-formfield__input-container:focus,
.nx-formfield:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

input[nxInput]::placeholder {
  color: #414141 !important;
}

.shopping-benefits--page .shopping-benefits--filters .main-content--row .shopping-filters .nx-formfield__input-container nx-dropdown .nx-dropdown__container .nx-dropdown__rendered {
  color: #414141 !important;
  font-weight: 300;
}

.nx-dropdown__panel-body {
  background-color: #ffffff !important;
}

.nx-dropdown-results__option-label {
  color: #414141 !important;
  box-shadow: unset !important;
  border: unset !important;
}

.nx-dropdown__panel-header {
  background-color: #ececec !important;
  color: #414141 !important;
}

.nx-dropdown-results__option:hover > .nx-dropdown-results__option-label {
  color: #006192 !important;
}

.nx-dropdown__panel {
  box-shadow: 0 2px 4px rgba(65, 65, 65, 0.5) !important;
  border-radius: 4px !important;
}

.nx-expansion-panel__header-content {
  background-color: inherit !important;
}

.faq-block .nx-expansion-panel__header-content .nx-expansion-panel__header-title {
  max-width: 712px;
  width: 100%;
  color: #006192 !important;
  font-size: 20px;
  line-height: 28px;
}

absolute .faq-accordion .nx-expansion-panel__header-content,
.faq-accordion .nx-expansion-panel__header-content {
  padding: 24px 32px !important;
}

.faq-block .nx-expansion-panel__body {
  padding: 0 32px 24px !important;
  max-width: 776px;
  margin-right: auto;
}

.faq-block .nx-expansion-panel__chevron {
  color: #006192 !important;
}

.faq-search p {
  margin: 0 !important;
  max-width: 250px;
  width: 100%;
}

input[nxInput]::placeholder {
  color: #414141 !important;
}

.shopping-benefits--filters .nx-tab-header__item.nx-tab-header__item--active {
  border-width: 4px;
  transition: font-weight 0.3s;
  color: #006192 !important;
  border-color: #006192 !important;
  padding-right: 0 !important;
  letter-spacing: 1px;
  font-weight: 700;
  padding-left: 0 !important;
  width: 166px !important;
}

.shopping-benefits--filters .nx-tab-header > button.nx-tab-header__item:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
  letter-spacing: 1px;
  color: #006192 !important;
  padding-bottom: 4px !important;
}

.tab__content .nx-grid__row {
  justify-content: center !important;
}

.tab__content .nx-message__content {
  color: #414141 !important;
}

.hero-section p {
  margin: auto !important;
}

.nx-popover__content {
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 2px 4px rgba(65, 65, 65, 0.5) !important;
}

.nx-button--tertiary {
  color: #006192 !important;
}

.nx-button--tertiary:hover:not(:disabled):not(:active) {
  background-color: #ececec !important;
  color: #006192 !important;
  border: transparent !important;
}

.cart-row--modifier {
  align-items: center !important;
  justify-content: end !important;
}

.nx-heading--section,
.voucher--page .voucher-related-products--heading,
.nx-heading--subsection-large {
  color: inherit !important;
}

.nx-checkbox__label,
.nx-stepper__input {
  color: #414141 !important;
}

.nx-grid.nx-grid--media-query {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.nx-checkbox__input:checked + .nx-checkbox__label .nx-checkbox__control {
  background-color: #007ab3 !important;
  border-color: #007ab3 !important;
  color: #ffffff !important;
}

.payment-buttons,
.payment-button--modal {
  justify-content: center !important;
}

.nx-message__content,
.nx-radio__label {
  color: #414141 !important;
}

.nx-modal__container {
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(65, 65, 65, 0.35);
  background: #ffffff;
  color: #414141;
}

.nx-button--primary:disabled {
  background-color: rgba(0, 122, 179, 0.4) !important;
  color: rgba(255, 255, 255, 0.4) !important;
  border-color: transparent !important;
}

.nx-radio__circle {
  border-color: #767676 !important;
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #767676 !important;
}

.nx-radio__input:checked + .nx-radio__label .nx-radio__dot {
  background-color: #007ab3 !important;
}

.nx-radio__input:checked + .nx-radio__label .nx-radio__circle {
  border-color: #007ab3 !important;
  background-color: transparent !important;
}

.nx-formfield {
  color: #414141 !important;
}

.nx-formfield.has-error:not(.nx-formfield--negative) {
  color: #dc3149 !important;
}

.nx-modal__content-wrapper {
  background-color: #ffffff !important;
}

.nx-formfield__label {
  color: #414141 !important;
}

.nx-formfield.has-error:not(.nx-formfield--negative):not(.has-outline) .nx-formfield__label {
  color: #ed6f7c !important;
}

.progress {
  justify-content: center !important;
}

.nx-spinner__spin-block {
  width: 50px !important;
  height: 50px !important;
  justify-content: center !important;
  margin: auto !important;
  text-align: center !important;
}

.faq-link--topblock p {
  max-width: 928px;
  width: 100%;
  color: #414141 !important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0 auto !important;
}

.btn-center {
  margin: auto !important;
}

.nx-stepper__input-underline {
  width: 100%;
  margin-top: 2px;
  height: 2px;
  background: #414141 !important;
}

.timing-data-row {
  justify-content: center !important;
}

.ticket-category--section .ticket-category--block.is-card-selected {
  background-color: #f5f5f5 !important;
  border: 1px solid #d9d9d9 !important;
}

.nx-spinner--small .nx-spinner__spin-block {
  border-width: 5px !important;
}

nx-spinner.nx-spinner--small {
  width: unset !important;
  height: unset !important;
}

.nx-spinner__spin-block {
  border-color: #007ab3 #ececec #ececec !important;
  border-color: #ececec !important;
  border-top-color: #007ab3 !important;
}

@media (max-width: 1096px) {
  .newsletter--card,
  .homepage--card {
    max-height: unset !important;
  }
  .static-card--lottery .nx-card-inner .imgContainer-container-wrapper {
    flex-direction: column !important;
    height: 100% !important;
  }
  .static-card.static-card--lottery {
    min-height: 430px !important;
  }
  .static-card.static-card--lottery .static-card--content .static-card--heading {
    font-size: 26px !important;
    line-height: 32px !important;
  }
  .static-card.static-card--lottery .static-card--content {
    min-height: 263px;
  }
}
.nx-checkbox__control {
  border-color: #007ab3 !important;
}

.newsletter--card .body p .nx-link,
.homepage--card .body p .nx-link,
.voucher--card .body p .nx-link,
.coupon--card .body p .nx-link {
  color: #414141 !important;
}

.single-voucher--card .voucher--card .media .image-div {
  max-width: 269px !important;
  width: 100% !important;
  max-height: 170px !important;
  height: 100% !important;
  display: flex !important;
  align-content: center !important;
  border-radius: 10px !important;
  background-position: center !important;
}

.single-voucher--card .voucher--card .media {
  background-size: unset !important;
  height: 192px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.nx-link--small:hover {
  color: #008ed6 !important;
}

.nx-button--secondary {
  border-color: #007ab3 !important;
}

.nx-switcher__toggle {
  background-color: #767676 !important;
}

.is-checked .nx-switcher__toggle {
  background-color: #007ab3 !important;
}

.nx-button--secondary:active {
  background-color: #003781 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}

.nx-button--secondary:disabled {
  background-color: transparent !important;
  color: rgba(0, 97, 146, 0.4) !important;
  border-color: rgba(0, 122, 179, 0.4) !important;
}

.nx-expansion-panel__chevron {
  color: #414141 !important;
}

@media (max-width: 703px) {
  .nx-card .media {
    height: 140px !important;
  }
  .cdk-overlay-pane {
    width: 100% !important;
  }
  .prospective-user-overlay {
    text-align: center;
  }
  .prospective-user-overlay .modal--body {
    text-align: left !important;
  }
  .cdk-overlay-pane {
    width: 100% !important;
  }
  .light-image-modal .nx-modal__container .nx-modal__close {
    top: 30px !important;
    right: 36px !important;
  }
  .table-point-account .table--content .credit-card--icon {
    left: 10px !important;
  }
  .shopping-benefits--page .shopping-benefits--filters .nx-pagesearch {
    max-width: 288px !important;
  }
  .direct-cart--page .information-block .subtotal-block .subtotal-block-point span.point + p {
    position: absolute;
    left: 0;
    top: 23px;
  }
  .product--carousel {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .information-block.nx-grid.nx-grid--media-query {
    padding: 0 !important;
  }
  body {
    padding-top: 60px !important;
  }
  .cart-row--modifier {
    justify-content: center !important;
  }
  .subtotal-block-point {
    display: flex !important;
  }
  .direct-cart--page .cart--header .cart--header-title,
  .table--contents .image--container,
  .table--cart.nx-grid.nx-grid--media-query {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .nx-formfield--type-nx-dropdown.nx-formfield.is-auto-floating {
    margin-left: 1rem !important;
    margin-right: 2rem !important;
  }
  .ticketQuantity-dropdown-container .nx-formfield--type-nx-dropdown.nx-formfield.is-auto-floating {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .faq-block nx-expansion-panel.nx-expansion-panel--light .nx-expansion-panel__header-content .nx-expansion-panel__header-title {
    max-width: 248px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
  }
  .faq-block nx-expansion-panel:first-child .nx-expansion-panel__header-content {
    align-items: start !important;
  }
  .nx-expansion-panel--regular[_nghost-ng-c2697047931] .nx-expansion-panel__header-content {
    align-items: start !important;
  }
  .voucher--page nx-message.context-info.mobile-message--container .nx-message__content {
    margin-left: 13px !important;
  }
  .voucher--page nx-message.context-info.mobile-message--container .nx-message__icon.nx-icon--s.ndbx-icon.nx-icon--info-circle {
    margin: 0 !important;
  }
  .shopping-benefits--page .shopping-benefits--filters .shopping-filter--search button {
    color: #ffffff !important;
    background-color: #007ab3 !important;
    border-color: transparent !important;
    position: absolute;
    right: -56px;
    top: -32px;
  }
  .coupon--card,
  .product--card,
  .voucher--card,
  .static--card,
  .homepage--card {
    min-height: 361px !important;
  }
  .cdk-global-overlay-wrapper .cdk-overlay-pane .modal-body--text p {
    padding-top: 1rem !important;
  }
  .faq-block .nx-expansion-panel__header-content .nx-expansion-panel__header-title {
    max-width: 248px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
  }
  .cart--page .cart--header .cart--header-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .is-total-amount {
    display: flex;
    justify-content: start;
    margin-top: -18px !important;
    margin-bottom: 24px !important;
  }
  .is-total-amount p {
    color: #414141 !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    text-align: left !important;
    margin: 0 !important;
    margin-right: 4rem !important;
  }
  .user-info--block .round--button {
    max-width: 100% !important;
  }
  .coupon--card,
  .product--card,
  .voucher--card,
  .static--card,
  .homepage--card {
    min-height: 266px !important;
  }
}
@media (max-width: 1096px) {
  .is-total-amount {
    display: flex;
    justify-content: start;
    margin-top: -32px;
    margin-bottom: 24px !important;
  }
  .is-total-amount p {
    color: #414141 !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    text-align: left !important;
    margin: 0 !important;
    margin-right: 4rem !important;
  }
}
.shopping-benefits--page .shopping-benefits--filters .nx-pagesearch > div.nx-grid .nx-grid__row .nx-pagesearch--content {
  max-width: unset !important;
}

@media (min-width: 992px) {
  .shopping-benefits--page .shopping-benefits--filters .nx-pagesearch {
    max-width: 373px !important;
  }
}
.nx-copy.nx-copy--normal,
.cart-modal--headline {
  color: #414141 !important;
}

.nx-button--emphasis {
  color: #fff !important;
  background-color: #f86200 !important;
  border-color: transparent !important;
}

.bg-img-link {
  display: inline !important;
}

.nx-expansion-panel--light .nx-expansion-panel__header-content {
  border-bottom: unset;
}

.directPurchaseAccordion.nx-accordion .nx-expansion-panel--regular {
  box-shadow: unset !important;
}

.directPurchaseAccordion.nx-accordion .nx-expansion-panel__chevron {
  color: #006192 !important;
}

.ticket-category--section .ticket-category--block {
  border-color: #d9d9d9 !important;
}

.nx-dropdown-results__option-checkicon {
  color: #414141 !important;
}

.category--row.nx-grid__row {
  align-items: center !important;
}

.carousel-options-container .open-lightbox nx-icon {
  color: #ffffff !important;
}

@media (max-width: 992px) {
  .faq-link--topblock p {
    text-align: left !important;
  }
  .faq-search p {
    margin: 0 auto 38px 0 !important;
  }
  .faq-search .search-box .search-field .nx-pagesearch .nx-grid {
    padding-left: 0 !important;
    padding-right: 32px !important;
  }
  .nx-expansion-panel__body,
  .nx-expansion-panel--regular .nx-expansion-panel__header-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .nx-grid.nx-grid--media-query {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .round--button {
    max-width: 95% !important;
  }
  .nx-button--block.btn--loadMoreBtn {
    max-width: 95% !important;
  }
  .faq-block .nx-expansion-panel__body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.faq-link--bottomblock div.footer-center-text {
  font-weight: 300 !important;
}

.nx-button--primary {
  color: #ffffff !important;
  background-color: #007ab3 !important;
  border-color: transparent !important;
}

.nx-tab-header__item {
  border-color: #006192 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
}

.context-info .nx-message__icon {
  color: #496ebd !important;
}

.transaction-points.nx-copy.nx-copy--normal {
  color: inherit !important;
}

.shopping-benefits--filters .nx-tab-header > button.nx-tab-header__item:last-child,
.nx-tab-header__item {
  color: #006192 !important;
}

.nx-tab-header__item--active {
  border-width: 4px !important;
  font-weight: 700 !important;
  transition: font-weight 0.3s !important;
  padding-bottom: 4px !important;
}

.round--button {
  border-radius: 25px !important;
  background-color: #496ebd !important;
  text-transform: none !important;
  min-height: 40px !important;
  padding: 0.028em 30px !important;
}

.shopping-benefits--filters .tab__content div.nx-grid__row.nx-justify-content-center .round--button.nx-button--medium {
  padding-left: 16px !important;
  padding-right: 16px !important;
  min-width: 288px;
}

.round--button span {
  font-size: 18px !important;
  letter-spacing: 0.2px !important;
}

.nx-expansion-panel__chevron:not(.is-disabled) .nx-expansion-panel__chevron {
  color: #006192 !important;
}

.ticketshop--page .ticket-table.sports .ticket-table--content .ticket-flap.raffle {
  max-width: 158px !important;
}

.footer.footer--voucher .nx-copy.nx-copy--normal {
  color: inherit !important;
}

.cart-delete-modal .nx-modal__backdrop .nx-modal__position .nx-modal__container {
  box-shadow: unset !important;
}

.nx-formfield.has-error:not(.nx-formfield--negative):not(.has-outline) .nx-formfield__prefix,
.nx-formfield.has-error:not(.nx-formfield--negative):not(.has-outline) .nx-formfield__suffix {
  color: #dc3149 !important;
}

.nx-checkbox__control {
  background-color: transparent !important;
  border-width: 2px !important;
  border-color: #767676 !important;
}

.cart-modal .nx-modal__backdrop .nx-modal__position .nx-modal__container {
  box-shadow: unset !important;
}

.nx-formfield.is-focused:not(.has-outline) .nx-formfield__input-container:after {
  height: 1px !important;
  background-color: #414141 !important;
  display: none !important;
}

.shopping-benefits--filters .nx-tab-header.nx-tab-header__item--active > button.nx-tab-header__item:first-child {
  padding-bottom: 4px !important;
}

.nx-tab-header__item:last-child:before {
  top: -1px !important;
  visibility: hidden !important;
}

.shopping-benefits--filters .nx-tab-header > button.nx-tab-header__item:first-child {
  padding-bottom: 4px !important;
}

.shopping-benefits--filters .nx-accordion .nx-expansion-panel__header-content .nx-expansion-panel__chevron {
  color: #006192 !important;
}

.Cultural--card .lightbox-mode .nx-modal__container {
  height: 85vh !important;
}

.ticketworldCart-conclusion .conclusion-row .notification-block nx-message {
  background-color: #ecf0f8 !important;
}

.cart--page .information-block .title h3 {
  width: 330px !important;
  max-width: 100% !important;
  line-height: 36px !important;
  letter-spacing: 0.3px !important;
  margin-bottom: 10px !important;
}

.nx-formfield__suffix {
  color: #414141 !important;
}

.shopping-benefits--filters .nx-expansion-panel--regular .nx-expansion-panel__header-content {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.table-point-account .table--content .credit-card--icon {
  position: absolute;
  left: 20px;
}

.advantage-section .advantage-section-contant .left-block h3 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: 0.2px !important;
  max-width: 100% !important;
  margin-bottom: 16px !important;
}

.nx-autocomplete-panel {
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px rgba(65, 65, 65, 0.5) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 280px !important;
  width: 100% !important;
  border-radius: 4px !important;
}

.nx-autocomplete-option__label {
  padding: 0 12px !important;
  margin: 0 -12px !important;
  color: #414141 !important;
  font-weight: 400 !important;
}

.newsletter--card .body p .nx-link,
.homepage--card .body p .nx-link {
  color: #414141 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  letter-spacing: 0.2px !important;
  line-height: 32px !important;
  text-align: center !important;
}

input[type=number] {
  color: #414141 !important;
  -webkit-text-fill-color: #414141 !important;
}

.nx-message__icon {
  margin-right: 16px !important;
}

@media (min-width: 1096px) {
  .faq-search .search-box .search-field {
    max-width: 392px;
    width: 100%;
    height: 28px;
  }
  .faq-search .search-box .nx-grid.nx-grid--media-query {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .timing-data-row .nx-grid__column--media-query.nx-grid__column-3xlarge-3 {
    max-width: 256px !important;
  }
  .is-total-amount {
    display: flex;
    justify-content: end;
    margin-top: -3.7rem;
    margin-bottom: 2rem;
  }
  .is-total-amount p {
    color: #414141 !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    text-align: left !important;
    margin: 0 !important;
    margin-right: 4rem !important;
  }
  .single-voucher--card .reward--card .media {
    height: 151px !important;
  }
}
.ticketworldCart-conclusion .conclusion-row.nx-grid__row.nx-grid__row--media-query .notification-block nx-message {
  padding: 31px !important;
}

.nx-radio__circle {
  border-color: #767676 !important;
  border: 2px solid #767676 !important;
}

.nx-radio__input:checked + .nx-radio__label .nx-radio__dot {
  background-color: #007ab3 !important;
}

.complete-personal-information .telephone .nx-copy.nx-copy--normal {
  margin-bottom: 4px !important;
}

.different-shipping-form-element .form-heading {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.horizontal-buttons nx-radio .nx-radio__label--text {
  display: inline-block;
  color: #414141;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.nx-radio__circle {
  border-color: #767676 !important;
  border-radius: 50% !important;
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 2px solid #767676 !important;
}

.nx-modal__container.ng-trigger-scaleUpDown {
  box-shadow: unset !important;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane .nx-modal__container {
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(65, 65, 65, 0.35);
  background: #ffffff !important;
  color: #414141 !important;
  margin: 0 !important;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.nx-modal__actions {
  border-top: unset !important;
}

.bread-crumbs a:active {
  color: #414141 !important;
}

.newsletter--card,
.homepage--card {
  min-height: 400px;
  padding: 0;
  margin: 0;
}

.newsletter--card .media,
.homepage--card .media {
  height: 151px !important;
  position: relative !important;
  top: 8px !important;
}

.nx-button--tertiary:disabled {
  background-color: transparent !important;
  color: rgba(0, 97, 146, 0.4) !important;
  border-color: transparent !important;
}

.nx-pagesearch .nx-formfield__prefix,
.nx-pagesearch .nx-formfield__suffix {
  color: #007ab3 !important;
}

.user-info--block nx-link a {
  align-items: center;
}

.faq-search .search-box .search-field .nx-pagesearch .nx-formfield__wrapper .nx-formfield__input-container {
  border-color: #006192 !important;
}

header .nx-header nx-header-brand img {
  margin-top: -8px !important;
}

.ticket-detail-page .main-ticket-page--description {
  margin-bottom: 0 !important;
}

.ticket-payment-complete--page .sale-success--card-container .purchase-msg--text {
  margin-bottom: 0 !important;
}

.ticket--carousel .lightbox-mode .nx-modal__container {
  height: 75vh !important;
}

.nx-card .nx-card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.footer-top .nx-card .nx-card-inner {
  align-items: center !important;
}

.change-email-modal.ng-star-inserted.nx-modal__content .nx-modal__actions {
  justify-content: center;
}

.cart-remove-actions.nx-modal__actions button {
  width: 256px;
}

.cart-remove-actions.nx-modal__actions button:first-child {
  margin-right: 32px !important;
}

.cart-remove-actions.nx-modal__actions {
  flex-wrap: wrap !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: auto !important;
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 1096px) {
  .cart-remove-actions.nx-modal__actions button:first-child {
    margin-right: 0 !important;
    margin-bottom: 16px !important;
  }
  .cart-remove-actions.nx-modal__actions button {
    width: 100%;
  }
}
.ticketshop-address-info .nx-margin-bottom-s.nx-link.block.nx-link--small {
  margin-top: 16px !important;
}

.nx-button--tertiary:active {
  background-color: #ececec !important;
  color: #006192 !important;
  border: transparent !important;
}

.remove-action-cta {
  display: none !important;
}

.cdk-global-overlay-wrapper .nx-modal__close {
  outline: none !important;
}

@media (min-width: 703px) and (max-width: 992px) {
  .shopping-benefits--page .shopping-benefits--filters .nx-pagesearch .nx-pagesearch__actions {
    position: absolute;
    right: -100%;
    top: 19px;
  }
}
@media (min-width: 703px) and (max-width: 1096px) {
  .static-card.static-card--lottery .static-card--content {
    max-width: 100%;
  }
  .user-info--block .round--button,
  .user-info--block .round--button .nx-button__content-wrapper {
    max-width: 100% !important;
  }
  .user-info--block .round--button .nx-button__content {
    flex-wrap: wrap !important;
  }
  .static-card--lottery .nx-card-inner .imgContainer-container-wrapper {
    flex-direction: row !important;
    height: 100%;
  }
  .static-card,
  .static-card.static-card--lottery {
    min-height: 100% !important;
  }
}
.newsletter--card .body,
.homepage--card .body {
  margin-top: 5px !important;
}

.nx-button--primary,
.nx-button--tertiary,
.nx-button--secondary {
  box-shadow: unset !important;
}

.lottery-address--page .button-row {
  justify-content: center !important;
}

.homepage--card .body {
  padding: 0 32px 44px !important;
  text-align: center !important;
}

.homepage--card .body a {
  text-align: center !important;
  display: block !important;
}

.homepage--card .media {
  background-color: #ffffff;
  height: 151px;
  background-size: 80px 80px;
  background-position: center 44px;
}

.homepage--card .body > div {
  max-width: 260px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
}

.footer.footer--homepage {
  border-top: none !important;
  padding: 0 0 48px !important;
  height: 71px !important;
}

.homepage--card .body > div p {
  color: #414141 !important;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
  -webkit-hyphens: none;
  hyphens: none;
}

.cart-session-modal .nx-modal__actions {
  justify-content: center !important;
}

.light-image-modal {
  max-width: 100% !important;
  width: 100% !important;
  height: 100vh !important;
}

.light-image-modal .nx-modal__content {
  height: 92vh !important;
  max-height: 100vh !important;
}

.light-image-modal .lightbox-image {
  max-width: 100% !important;
  width: 100% !important;
}

.light-image-modal .nx-modal__close {
  top: 70px !important;
  right: 3.5% !important;
  z-index: 9999 !important;
}

.light-image-modal .nx-modal__container.ng-trigger.ng-trigger-modalContainer.ng-trigger-slideInOut.ng-star-inserted {
  border-radius: unset !important;
  padding-left: unset !important;
  padding-right: unset !important;
  overflow-x: hidden !important;
}

.light-image-modal .nx-modal__container #Sports--card .media-list {
  height: 100% !important;
}

.nx-modal__actions {
  margin-bottom: 0 !important;
}

.coupon--page .nav-bar-sticky,
.cart--page .nav-bar-sticky {
  position: fixed !important;
  width: 100%;
}

.coupon--page .cart-row--container,
.cart--page .main--whitebg {
  padding-top: 50px !important;
}

.copy-success--btn.nx-button--cta {
  background-color: #3da556 !important;
  color: #ffffff !important;
}

.is-auto-floating.has-error .nx-formfield__label {
  color: #dc3149 !important;
}

.is-auto-floating.has-error.is-floating.is-filled .nx-formfield__label {
  color: #414141 !important;
}

.is-auto-floating.has-error.is-floating.is-focused .nx-formfield__label {
  color: #414141 !important;
}

.nx-formfield.is-focused:not(.nx-formfield--negative):not(.has-error) {
  color: #006192 !important;
}

:focus {
  box-shadow: unset !important;
}

.nx-dropdown-item--active .nx-dropdown-results__option-label {
  outline: 2px dotted #006192 !important;
  outline-offset: 4px;
  outline-color: #006192 !important;
}

.nx-radio__circle,
.nx-checkbox__control,
.nx-link.nx-link--small a {
  box-shadow: unset !important;
}

.lottery-page--error .context-error {
  background-color: #fce5eb !important;
}

.static-card--lottery .nx-card-inner {
  width: 100%;
}
.static-card--lottery .nx-card-inner .imgContainer-container-wrapper {
  display: flex;
  width: 100%;
}

.nx-button--primary.nx-button--negative {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #006192 !important;
}

.nx-button--primary.nx-button--negative:hover:not(:disabled):not(:active) {
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
  color: #006192 !important;
}

.allianzProgram--main .nx-copy.nx-copy--normal {
  margin-left: auto !important;
  margin-right: auto !important;
}

.regular-user-overlay .modal--body {
  text-align: center !important;
}

.regular-user-overlay .modal--body > p {
  text-align: center !important;
}

@media only screen and (min-width: 704px) {
  .nx-header__navigation .nx-header__navigation-items {
    gap: 44px;
    justify-content: center !important;
  }
  .nav-bar-sticky .nx-grid--max-width .nx-header__row {
    width: 100% !important;
  }
  .navigation-row {
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media only screen and (min-width: 704px) {
  .navbar.sticky .nx-grid {
    overflow-x: scroll;
    margin-left: 16px !important;
  }
}
@media only screen and (min-width: 1300px) {
  .navbar.sticky .nx-grid {
    width: 100%;
    max-width: 100% !important;
    overflow: unset !important;
    margin-left: unset !important;
  }
}