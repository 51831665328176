//Breakpoints
$xs-breakpoint: 320px;
$sm-breakpoint: 703px;
$md-breakpoint: 992px;
$lg-breakpoint: 1096px;
// #007ab3
//Color
$white: #ffffff;
$black: #000000;
$main-gbg: #f5f5f5;
$black-fade: #414141;
$light-grey: #d9d9d9;
$allianz-blue: #006192;
$allianz-blue-hover: #008ed6;
$allianz-green: #3da556;
$availability-color: #ffd54e;
$stock-color: #ff934f;
$expired-color: #dc3149;
$coupon-border: #f2f2f2;
$label-border: #999999;
$top-label: #767676;
$bottom-label: #414141;
$coupon-color: #5a5360;
$coupon-discount: #00808f;
$carousel-bg: #ececec;
$modal-close-icon: #767676;
$round-button: #496ebd;
$progress_bar: #007ab3;
$coupon-disc-badge: #007d8c;
$link-row: #ececec;
$maintenance-page-bg: #e6f4f6;
$raffle: #8a679c;
$diff-days: #5a3982;
$icon-color: #5ba25e;
$list-check: #5A5360;
$context-error-bg: #fbeaec;
$context-warning-bg: #fdf8e9;
$context-info-bg: #ecf0f8;
$context-success-bg: #e8f3e9;
$hotel-flap: #49648C;
$danger-color: #ed6f7c;
$deep-ocean-blue: #003781;
$orange-color: #f86200;

$sent: #B1DADD;
$ordered: #EBE1BF;
$not-won: #F7C7C3;
$participation: #DAD0E1;
$won: #FFE8B0;
$gutschrift: #E3EBAF;
$hotel-flap-color: #2f79b3;
$lottery-silver-gray: #D8D8D8;
$error-message-bg: #fce5eb;
