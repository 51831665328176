@import "src/assets/scss/_legacy-utilities.scss";
@import "src/assets/scss/_mixins.scss";
@import "src/assets/scss/_variables.scss";

.text-center {
  text-align: center !important;
}

.text-center-large {
  @media only screen and (min-width: 704px) {
    text-align: center !important;
  }
}

.d-block {
  display: block !important;
  width: 100%;
}

.d-inline {
  display: inline;
}

.d-none {
  display: none !important;
}

.main-content--row {
  @include small {
    >div:nth-child(1) {
      order: 2 !important;
    }

    >div:nth-child(2) {
      order: 1 !important;
    }

    >div:nth-child(3) {
      order: 3 !important;
    }
  }
}

.related-products--responsive {
  @include small {
    // padding-left: 10px;
    // padding-right: 10px;
  }

  >div {
    @include max-medium {
      padding-bottom: 10px !important;
    }

    @include small {
      padding-bottom: 0px !important;
    }
  }

  .related-products--block {
    &:nth-child(odd) {
      @include small {
        padding-right: 4px;
      }
    }

    &:nth-child(even) {
      @include small {
        padding-left: 4px;
      }
    }

    @include small {
      margin-bottom: 16px !important;
    }
  }
}

.media--caption,
.caption {
  padding: 8px 17px;
  font-size: 14px;
  font-weight: 600;
  border-bottom-right-radius: 16px;
  width: auto;
  line-height: 16px;
  letter-spacing: 0.2px;

  &.availability {
    background-color: $availability-color;
  }

  &.stock {
    background-color: $stock-color;
  }

  &.expired {
    background-color: $expired-color;
    color: $white;
  }
}

.media--caption {
  min-height: 5%;
  padding: 8px 20px;
}

.caption {
  max-width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;

  @include small {
    max-width: 88.571428571%;
    padding: 8px !important;
  }

  &.availability {
    width: 181px;
    padding-left: 16px;

    @include small {
      width: 124px;
    }
  }
}

.detailed-content {
  .description {
    // max-width: 63.888888888% !important;
    margin-bottom: 104px !important;

    @include small {
      // max-width: 95% !important;
      margin-bottom: 64px !important;
    }

    h2 {
      color: $black-fade;
      margin-bottom: 40px;
      max-width: 928px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include small {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 24px;
        max-width: 100%;
      }
    }

    .content {
      margin-left: auto;
      margin-right: auto;

      @include large-only {
        max-width: 736px;
        width: 100%;
      }

      p {
        line-height: 24px;
        font-size: 16px !important;
        margin-bottom: 24px;

        @include small {
          margin-bottom: 16px;
          max-width: 100%;
        }

        a {
          color: $allianz-blue;
          font-weight: 600;

          &:hover {
            color: $allianz-blue-hover;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        height: 100%;
        width: 100%;
      }

      ul,
      ol {
        padding-left: 24px;

        li {
          font-size: 16px;
          margin-bottom: 24px;

          @include small {
            margin-bottom: 16px;
          }

          a {
            color: $allianz-blue;
            font-weight: 600;

            &:hover {
              color: $allianz-blue-hover;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul {
        list-style: disc outside;
      }

      ol {
        counter-reset: allianz-list-counter;
        list-style: none;

        li {
          margin-left: 12px;
          counter-increment: allianz-list-counter;
          position: relative;

          &::before {
            content: counter(allianz-list-counter);
            color: $white;
            font-size: 16px;
            line-height: 20px;
            position: absolute;
            // --size: 24px;
            left: -36px;
            line-height: 24px;
            width: 24px;
            height: 24px;
            top: 0;
            background: #827e85;
            border-radius: 50%;
            text-align: center;
          }
        }
      }
    }
  }
}

.option-btn--modifier {
  button {
    width: 52.759% !important;
    // margin-bottom: 1em !important;

    @include max-medium {
      width: 100% !important;
    }

    nx-icon {
      margin-right: 10px;
    }
  }
}

.link-row--modifier {
  height: 54px;
  padding-top: 15px;
  padding-bottom: 15px;

  @include small {
    padding-left: 0;
  }

  nx-icon {
    font-size: 24px;
    color: $allianz-blue;

    @include small {
      margin-left: 0 !important;
    }
  }

  nx-link {
    font-size: 18px;
  }
}

.round--button {
  border-radius: 25px !important;
  background-color: $round-button !important;
  text-transform: none !important;
  min-height: 40px !important;
  padding: 0.028em 30px !important;

  span {
    font-weight: normal;
    font-size: 1em;

    &.points--val {
      font-weight: bold;
    }
  }
}

.nx-spinner--large {
  margin: auto;
}

.wishlist--flag {
  font-weight: 700 !important;
}

:focus {
  outline: 2px dotted $allianz-blue !important;
  outline-offset: 4px;
}

body.using-mouse *:focus {
  outline: none !important;
}

body:not(.using-mouse) {
  .nx-switcher__input:focus + .nx-switcher__label .nx-switcher__toggle {
    outline: 2px dotted $allianz-blue !important;
    outline-offset: 4px;
  }

  .slick-slide:focus {
    border: 2px dotted $allianz-blue;
  }
  
  .nx-dropdown__panel-body.is-stable .nx-dropdown-item--active .nx-dropdown-results__option .nx-dropdown-results__option-label {
    outline: 2px dotted $allianz-blue !important;
    outline-offset: 4px;
  }
  
  .nx-checkbox__input:focus + .nx-checkbox__label .nx-checkbox__control {
    outline: 2px dotted $allianz-blue !important;
    outline-offset: 4px;
  }
  
  .open-lightbox:focus {
    outline: none !important;
  
    nx-icon {
      outline: 2px dotted $allianz-blue;
      outline-offset: 4px;
    }
  }
  
  .name-carousel:focus {
    outline: none !important;
  
    span {
      outline: 2px dotted $allianz-blue;
      outline-offset: 4px;
    }
  }
  
  .nx-radio__input:focus + .nx-radio__label .nx-radio__circle {
    outline: 2px dotted $allianz-blue;
    outline-offset: 4px;
  }

  .button-thumb:focus {
    outline: none !important;
    border: 2px dotted $allianz-blue;
    padding: 4px;
  }

  nx-expansion-panel-header:focus  .nx-expansion-panel__header-content {
    border: 2px dotted $allianz-blue;
    z-index: 1;
  }

  .nx-autocomplete-option.nx-active .nx-autocomplete-option__label {
    outline: 2px solid $allianz-blue;
    outline-offset: 4px;
  }
}

nx-icon {
  &:hover {
    color: $allianz-blue-hover;
  }
}

em {
  font-style: italic;
}

.carousel-container {
  @include small {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.padding-left-none {
  padding-left: 0px !important;
}

.wishlist-icon {
  cursor: pointer !important;
}

.cart-row--modifier {
  height: 55px;

  @include small {
    height: 78px;
  }
}

.cart-row--container {
  background-color: $white;
  border-bottom: 1px solid $light-grey;
}

.cart-link {
  display: flex !important;
  position: relative;
  // justify-content: flex-end;

  width: 181px;

  .cart-count {
    position: absolute;
    top: -6px;
    right: -6px;
    background: #e4003a;
    color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 12px;
    -webkit-box-align: center;
    align-items: center;
  }
}

.cart-icon {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  height: 24px;
  width: 24px;
  margin-left: 8px;

  &.empty {
    background-image: url("../images/shopping_cart-empty.svg");
  }

  &.full {
    background-image: url("../images/shopping_cart-full.svg");
  }
}

.stepper-error {
  -webkit-text-fill-color: $expired-color !important;
}

.mobile-d-none {
  @media only screen and (max-width: 740px) {
    display: none;
  }
}

.margin-smaller {
  @include small {
    margin-bottom: 32px;
  }
}

.pointer {
  cursor: pointer !important;
}

.noPointer {
  cursor: initial !important;
  pointer-events: none !important;
  outline: none !important;
}