//Breakpoints

@mixin extra-small-only {
  @media (max-width: $xs-breakpoint) {
    @content;
  }
}

@mixin small {
  @media (max-width: $sm-breakpoint) {
    @content;
  }
}

@mixin max-medium {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin small-only {
  @media (min-width: 321px) and (max-width: $sm-breakpoint) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: 704px) and (max-width: $md-breakpoint) {
    @content;
  }
}

@mixin large-only {
  @media (min-width: $md-breakpoint) {
    @content;
  }
}

@mixin extra-large-only {
  @media (min-width: $lg-breakpoint) {
    @content;
  }
}

@mixin medium-large {
  @media (min-width: $sm-breakpoint) {
    @content;
  }
}

// Display

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin cart-checkout {
  border-radius: 3px;

  @include small {
    border-radius: 16px 16px 0 0;
  }
}

@mixin no-hyphens {
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}

@mixin cost-style {
  max-width: 160px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: $black-fade;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 32px;
  text-align: right;
}

@mixin checkoutComponent-table {

  .ticketworldCart-table-checkoutcomponent,
  .ticketworldCart-table-confirmation {
    @include max-medium {
      background-color: $main-gbg;
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .ticketworldCart-table--headings {
      height: 72px;
      padding: 24px;
      border-bottom: 2px solid $black-fade;

      margin-right: 0;
      margin-left: 0;

      @include max-medium {
        border: none;
        display: none;
      }

      >div {
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
      }

      .heading {
        display: inline-block;
        max-width: 160px;
        width: 100%;
        color: $black-fade;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;

        &.last {
          text-align: right;
        }
      }
    }

    .ticketworldCart-table--contents {
      min-height: 160px;
      padding: 32px;

      margin-right: 0;
      margin-left: 0;

      @include max-medium {
        border: 1px solid $light-grey;
        border-radius: 4px;
        padding: 0;
        background-color: $white;
      }

      >div {
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
      }

      .table-col-one {
        @include max-medium {
          padding: 0;
        }
      }

      .table-col-two {
        @include max-medium {
          padding: 16px;
          max-width: 100%;
          flex: 0 0 100%;

          color: $black-fade;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 24px;
        }

        .inner-heading {
          display: none;

          @include max-medium {
            display: inline-block;
          }
        }
      }

      .table-col-three {
        @include max-medium {
          display: none;
        }
      }

      .table-col-four {
        @include max-medium {
          display: none;
        }
      }

      .ticketworldCart-ticketdata {
        display: flex;

        @include max-medium {
          flex-direction: column;
        }

        .ticket-img {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 96px;
          width: 96px;
          margin-right: 32px;
          background-color: $main-gbg;

          color: $allianz-blue;

          @include max-medium {
            width: 100%;
            margin-right: 0;
            height: 120px;
          }

          nx-icon {
            font-size: 57px;

            @include max-medium {
              font-size: 71px;
            }
          }
        }

        .ticket-description {
          max-width: 384px;
          width: 100%;

          @include max-medium {
            max-width: 100%;
            padding: 16px 16px 20px 16px;
            border-bottom: 1px solid $light-grey;
          }

          .name {
            max-width: 384px;
            width: 100%;
            margin-top: 0;
            margin-bottom: 8px;
            color: $black-fade;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 24px;

            @include max-medium {
              letter-spacing: 0px;
              margin-bottom: 4px;
            }
          }

          .additional-info {
            max-width: 384px;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            color: $black-fade;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 20px;

            span {
              font-weight: bold;
            }

            p:first-child {
              font-weight: bold;
            }
          }
        }
      }

      .ticketQuantity {
        max-width: 96px;
        width: 100%;

        font-size: 20px;
        letter-spacing: 0.2px;
        line-height: 28px;

        @include max-medium {
          font-size: 18px;
          line-height: 24px;
          max-width: 100%;
        }
      }

      .ticket-cost {
        @include cost-style;
      }
    }
  }
}

@mixin checkoutComponent-conclusionBlock {
  .ticketworldCart-conclusion-checkoutcomponent {
    @include max-medium {
      background-color: $main-gbg;
    }

    .conclusion-row {
      background-color: $main-gbg;
      padding: 32px 32px 40px 32px;

      margin-right: 0;
      margin-left: 0;

      min-height: 209px;

      @include max-medium {
        padding: 0;
        padding-bottom: 32px;
      }

      .conclusion-col--three {
        @include max-medium {
          display: none;
        }
      }

      .conclusion-col--one {
        padding-left: 0;

        @include max-medium {
          order: 3;
          padding: 0;
        }
      }

      .conclusion-col--two {
        @include max-medium {
          order: 1;
          padding: 0;
          margin-bottom: 24px;
        }
      }

      .conclusion-col--four {
        @include max-medium {
          order: 2;
          padding: 0;
        }
      }

      .staticblock-editable {
        max-width: 320px;
        width: 100%;
        color: $black-fade;

        .staticblock-heading {
          font-size: 24px;
          letter-spacing: 0.2px;
          line-height: 32px;
          margin-top: 0;
          margin-bottom: 8px;
          font-weight: normal;

          @include max-medium {
            font-size: 20px;
            letter-spacing: 0.17px;
          }
        }

        .staticblock-data {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      // .calculation-block {
      //   .calculation-component {
      //     display: flex;
      //     justify-content: space-between;

      //     margin-bottom: 8px;

      //     .heading {
      //       max-width: 256px;
      //       width: 100%;
      //       color: $black-fade;
      //       font-size: 14px;
      //       letter-spacing: 0.2px;
      //       line-height: 20px;
      //       margin-top: 0;
      //       margin-bottom: 0;

      //       @include max-medium {
      //         max-width: 136px;
      //       }

      //       .subhead {
      //         font-size: 12px;
      //         letter-spacing: 0.5px;
      //         line-height: 16px;
      //         margin-top: 8px;

      //         @include max-medium {
      //           margin-top: 4px;
      //           line-height: 24px;
      //         }
      //       }
      //     }

      //     .data {
      //       max-width: 223px;
      //       width: 100%;
      //       color: $black-fade;
      //       font-size: 14px;
      //       letter-spacing: 0.2px;
      //       line-height: 20px;
      //       text-align: right;
      //       margin-top: 0;
      //       margin-bottom: 0;

      //       @include max-medium {
      //         max-width: 136px;
      //       }
      //     }
      //   }

      //   .calculation-result {
      //     display: flex;
      //     justify-content: space-between;

      //     border-top: 2px solid $black-fade;
      //     padding-top: 8px;

      //     .heading {
      //       max-width: 270px;
      //       width: 100%;
      //       color: $black-fade;
      //       font-size: 14px;
      //       font-weight: bold;
      //       letter-spacing: 0.2px;
      //       line-height: 20px;

      //       @include max-medium {
      //         max-width: 136px;
      //       }
      //     }

      //     .data {
      //       max-width: 221px;
      //       width: 100%;
      //       color: $black-fade;
      //       font-size: 14px;
      //       font-weight: bold;
      //       letter-spacing: 0.2px;
      //       line-height: 20px;
      //       text-align: right;

      //       @include max-medium {
      //         max-width: 135px;
      //       }
      //     }
      //   }
      // }

      .calculation-block {
        .calculation-component {
          display: flex;
          justify-content: space-between;

          margin-bottom: 24px;

          @include small {
            margin-bottom: 16px;
          }

          .heading {
            max-width: 256px;
            width: 100%;
            color: $black-fade;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0.2px;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 0;

            @include max-medium {
              max-width: 192px;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
            }

            .subhead {
              font-size: 16px;
              letter-spacing: 0;
              line-height: 24px;
              font-weight: 400;
              margin-top: 8px;

              @include small {
                margin-top: 4px;
              }
            }

            h2 {
              max-width: 256px;
              width: 100%;
              color: $black-fade;
              font-size: 24px;
              font-weight: 400;
              letter-spacing: 0.2px;
              line-height: 32px;
              margin-top: 0;
              margin-bottom: 0;

              @include max-medium {
                max-width: 192px;
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
              }
            }
          }

          .data {
            max-width: 223px;
            width: 100%;
            color: $black-fade;
            font-size: 24px;
            letter-spacing: 0.2px;
            line-height: 32px;
            text-align: right;
            margin-top: 0;
            margin-bottom: 0;

            @include max-medium {
              max-width: 95px;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
            }
          }
        }

        .calculation-result {
          display: flex;
          justify-content: space-between;

          border-top: 2px solid $black-fade;
          padding-top: 24px;

          .heading {
            max-width: 270px;
            width: 100%;
            color: $black-fade;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 32px;

            @include max-medium {
              max-width: 136px;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
            }
          }

          .data {
            max-width: 221px;
            width: 100%;
            color: $black-fade;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 32px;
            text-align: right;

            @include max-medium {
              max-width: 135px;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

@mixin cart-session-modal {
  .cart-session-modal {
    z-index: 20 !important;
    @include no-hyphens;

    .nx-modal__backdrop {
      .nx-modal__position {

        .nx-modal__container {
          width: 736px;

          @include max-medium {
            width: 100%;
            max-height: 100% !important;
          }

          .nx-modal__content-wrapper {
            @include max-medium {
              padding: 40px 32px 40px 32px !important;
              max-height: 100% !important;
            }

            h3 {
              &.cart-modal--headline {
                max-width: 608px;
                width: 100%;
                margin: 0 auto 24px auto;
                font-size: 30px;
                font-weight: 300;
                letter-spacing: 0.3px;
                line-height: 36px;

                @include max-medium {
                  font-size: 26px;
                  line-height: 32px;
                }
              }
            }

            p {
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 32px;
              text-align: center;

              @include max-medium {
                text-align: left;
              }
            }

            .button-container {
              text-align: center;
              max-width: 100%;


              button {
                width: 352px;

                @media only screen and (max-width: 863px) {
                  width: 100%;
                  border-width: 1px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin custom-popover {
  .popover__wrapper {
    position: relative;
    display: inline;
    border: none;
    background-color: $white;
    padding: 0;

    .popover__content {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: -150px;
      transform: translate(0, 10px);
      background-color: $white;
      padding: 23px;
      border: 1px solid $light-grey;
      box-shadow: 0 2px 4px rgba(65, 65, 65, .5);
      border-radius: 4px;

      &:before {
        position: absolute;
        z-index: -1;
        content: "";
        width: 12px;
        height: 12px;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0) rotate(225deg);
        border-left: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        transition-duration: 0.3s;
        transition-property: transform;
        background-color: $white;
        box-shadow: 2px 2px 1px 1px $white, -2px -2px 4px rgba(65, 65, 65, .5);

        @media only screen and (max-width: 320px) {
          left: 66%;
          transform: translate(-66%, 0) rotate(225deg);
        }
      }

      .popover__message {
        display: block;
        text-align: left;

        @media only screen and (max-width: 1024px) {
          text-align: center;
        }

        @include no-hyphens;
      }
    }

    &:hover,
    &:focus {
      .popover__content {
        @media only screen and (min-width: 1025px) {
          width: 400px;
          z-index: 10;
          opacity: 1;
          visibility: visible;
          left: 50%;
          transform: translate(-50%, -124%);
        }
      }
    }

    &.active {
      .popover__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        left: 50%;
        transform: translate(-50%, -111%);

        @media only screen and (min-width: 703px) and (max-width: 1024px) {
          width: 288px;
          transform: translate(-50%, -124%);
        }

        @media only screen and (max-width: 320px) {
          width: 288px;
          left: unset;
          transform: translate(-61.5%, -122%);
        }
      }
    }
  }
}

@mixin faq-search-styles {
  .cdk-overlay-container {
    &.custom-cdk-overlay-pane {
      .cdk-overlay-connected-position-bounding-box {
        max-width: 288px;
        width: 100% !important;
        height: auto !important;
      }


      .nx-autocomplete-option__label {
        @include no-hyphens;
        white-space: break-spaces;
      }
    }
  }

  .faq-search {
    display: flex;
    margin: auto;
    justify-content: center;

    @include small {
      flex-wrap: wrap;
    }

    p {
      margin: 0;
      max-width: 250px;
      width: 100%;

      @include small {
        margin: 0 auto 38px 0;
      }
    }

    .search-box {
      display: flex;
      max-width: 416px;
      width: 100%;
      align-items: center;

      .search-field {
        max-width: 392px;
        width: 100%;
        height: 28px;

        .nx-pagesearch {
          padding: 0;
          box-shadow: none;

          .nx-grid {
            padding: 0 16px 0 24px;

            @include small {
              padding: 0 16px 0 0;
            }
          }

          .nx-formfield__wrapper {
            padding: 0;

            .nx-formfield__input-container {
              padding: 0;
              max-height: 28px;
              border-bottom: 1px solid $allianz-blue;

              .nx-formfield__input {
                .c-input {
                  font-size: 18px;
                  line-height: 28px;

                  &::placeholder {
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }

      .search-button {
        border: 0;
        padding: 0;
        background-color: $white;
        color: $allianz-blue;
        height: 28px;
        display: flex;
        align-items: center;

        @include small {
          display: none;
        }

        &--mobile {
          display: none;

          @include small {
            display: block;
          }
        }
      }
    }
  }


  .faq-search--block {
    &.main {
      padding: 42px 0 42px 0;
      box-shadow: inset 0 0 0 1px $light-grey;
      position: sticky;
      top: 118px;
      z-index: 1;
      background-color: $white;

      @include small {
        padding: 24px 0 38px 0;
        top: 109px;
      }
    }
  }
}