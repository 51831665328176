@import './variables';
h1 {
    font-size: 54px;
    font-weight: 300;
    .bold-heading {
        font-weight: 700;
    }
}

h2 {
    font-size: 40px;
    font-weight: 300;
}

h3 {
    font-weight: 300;
    .large-subheading {
        font-size: 30px;
    }
    .medium-subheading {
        font-size: 26px
    }
    .small-subheading {
        font-size: 24px;
        font-weight: 600;
    }
}

h4 {
    font-size: 20px;
    font-weight: 300;
}

.text--success {
    color: $allianz-green;
    font-size: 16px;
}