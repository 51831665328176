a.optanon-toggle-display{color:#006192;cursor:pointer;font-size:16px;font-weight:600;letter-spacing:1px;position:relative;text-decoration:none;transition:color .2s ease,box-shadow .2s ease;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;text-decoration:none !important}
#optanon{font-family:"Allianz Neo",sans-serif !important}
#optanon *{font-family:"Allianz Neo",sans-serif !important}
#optanon #optanon-popup-bg{background:rgba(0,0,0,.35)}
#center-tile-banner-popup.optanon-alert-box-wrapper{background-color:#FFF !important;box-shadow:0 8px 24px rgba(65,65,65,.35)}
#onetrust-banner-sdk #onetrust-reject-all-handler{background-color:#007ab3 !important;border-color:#007ab3 !important;border-radius:4px}
#onetrust-consent-sdk #onetrust-accept-btn-handler{background-color:#007ab3 !important;border-color:#007ab3 !important;border-radius:4px}
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link{color:#006192 !important;text-transform:uppercase;font:400 !important;border:0;letter-spacing:1px}
#onetrust-banner-sdk{font-size:13px !important}
#onetrust-consent-sdk #onetrust-pc-sdk button{background-color:#007ab3 !important;border-color:#007ab3 !important;color:#fff;border-radius:4px}
#onetrust-pc-sdk .pc-logo{visibility:visible}
#onetrust-pc-sdk .pc-footer-logo{display:none}
#onetrust-pc-sdk #content{z-index:2147483646 !important;background-color:#fff !important;margin-right:30px !important;margin:10px 25% 10px 25% !important;width:auto !important}
#onetrust-pc-sdk .ot-sdk-row{width:auto !important;margin:30px !important}
#onetrust-pc-sdk #pc-policy-text{padding-left:30px;width:auto !important}
#onetrust-pc-sdk #pc-title{margin-left:30px;width:auto !important}
#onetrust-pc-sdk .category-header{margin-left:0}
#onetrust-pc-sdk #cookie-preferences{margin-left:30px}
#optanon fieldset{margin-top:7px}
#optanon fieldset{margin-top:7px}
#optanon fieldset label{white-space:nowrap}
#optanon #optanon-popup-more-info-bar p{margin:10px 0 0 0}
#optanon #optanon-popup-body{background-color:#fff;width:100%;position:relative}
#optanon #optanon-popup-body h2{margin:0 !important;font-size:28px;font-weight:300;font-family:"Allianz Neo",sans-serif}
#optanon #optanon-popup-body-left{max-width:215px;margin:20px 0 20px 40px;padding:0;display:inline-block;vertical-align:top;width:auto !important}
#optanon #optanon-popup-body-right{max-width:430px;padding:0;margin:20px 20px 20px 20px}
#optanon #optanon-popup-body div#optanon-main-info-text{max-height:395px;overflow-x:hidden}
#optanon #optanon-popup-body div#optanon-main-info-text div.optanon-cookie-list span.optanon-subgroup-header{width:75%}
#optanon #optanon-popup-body h3{width:78%}
#optanon .center-tile-preference-center-title.h1{font-weight:200}
#optanon #optanon-popup-top .optanon-close{background:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiM2MTYxNjEiIGQ9Ik0xOSw2LjRMMTcuNiw1TDEyLDEwLjZMNi40LDVMNSw2LjRsNS42LDUuNkw1LDE3LjZMNi40LDE5bDUuNi01LjZsNS42LDUuNmwxLjQtMS40TDEzLjQsMTJMMTksNi40eiIvPg0KPC9zdmc+");background-size:24px auto;position:relative;z-index:1000;cursor:pointer}
#optanon #optanon-popup-wrapper .optanon-button-wrapper{margin:9px 10px 11px 0 !important;}
#optanon #optanon-popup-body-left-shading,#optanon.modern #optanon-branding-top-logo,#optanon.modern #optanon-popup-body-left-shading{display:none}
#optanon-popup-body-right{display:inline-block;width:400px;margin:10px 0 10px 10px;vertical-align:top;padding:0}
#optanon #optanon-menu{width:auto !important;margin:0}
#optanon #optanon-menu li{padding-right:0 !important}
#optanon #optanon-menu .menu-item-selected p a{background:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwMDYxOTIiIGQ9Ik0xNSwxN2MtMC4zLDAtMC41LTAuMS0wLjctMC4zYy0wLjQtMC40LTAuNC0xLDAtMS40bDIuMy0yLjNINmMtMC42LDAtMS0wLjQtMS0xczAuNC0xLDEtMWgxMC42bC0yLjMtMi4zDQoJYy0wLjQtMC40LTAuNC0xLDAtMS40czEtMC40LDEuNCwwbDQsNGMwLjIsMC4yLDAuMywwLjQsMC4zLDAuN2wwLDBjMCwwLjMtMC4xLDAuNS0wLjMsMC43bC00LDRDMTUuNSwxNi45LDE1LjMsMTcsMTUsMTd6Ii8+DQo8L3N2Zz4=") no-repeat right center;background-size:24px 24px}
#optanon #optanon-menu li p{margin:0 !important;padding:0;width:100%}
#optanon #optanon-menu li p a{padding:12px 22px 12px 8px;color:#006192 !important;font-weight:400;display:block;font-size:16px;font-family:"Allianz Neo",sans-serif}
#optanon #optanon-popup-wrapper{-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;overflow:hidden;height:auto;box-shadow:0 8px 24px rgba(65,65,65,.35)}
#optanon #optanon-popup-wrapper .optanon-white-button-middle,.optanon-alert-box-button-middle,.optanon-alert-box-accept-button{border:2px solid transparent;border-radius:5px;color:#fff;cursor:pointer;font-size:14px;font-weight:600;letter-spacing:.1rem;text-transform:none;transition:background-color .2s ease,border-color .2s ease,color .2s ease,box-shadow .2s ease;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:auto;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;display:inline-block}
#optanon #optanon-popup-wrapper .optanon-white-button-middle a{color:#007ab3 !important;text-transform:uppercase;font-size:16px;font-weight:600;letter-spacing:1px}
#optanon #optanon-popup-wrapper .optanon-bottom-spacer{height:0}
#optanon #optanon-popup-body h3{margin:0;padding:12px 0 12px;vertical-align:top;font-weight:normal;font-size:18px}
#optanon #optanon-popup-body div#optanon-main-info-text{color:#414141;font-size:16px;line-height:24px;margin:0;padding:0}
#optanon #optanon-popup-bottom{padding-bottom:0}
#optanon #optanon-popup-bottom-logo{visibility:hidden}
#optanon #optanon-menu .menu-item-about{background:0}
#optanon #optanon-menu .menu-item-necessary{background:0}
#optanon #optanon-menu .menu-item-performance{background:0}
#optanon #optanon-menu .menu-item-functional{background:0}
#optanon #optanon-menu .menu-item-advertising{background:0}
#optanon #optanon-menu .menu-item-social{background:0}
#optanon #optanon-menu .menu-item-moreinfo{background:0}
.optanon-alert-box-wrapper .optanon-alert-box-corner-close button{background:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiM2MTYxNjEiIGQ9Ik0xOSw2LjRMMTcuNiw1TDEyLDEwLjZMNi40LDVMNSw2LjRsNS42LDUuNkw1LDE3LjZMNi40LDE5bDUuNi01LjZsNS42LDUuNmwxLjQtMS40TDEzLjQsMTJMMTksNi40eiIvPg0KPC9zdmc+");top:10px;right:10px}
#center-tile-banner-popup button.accept-cookies-button{margin-bottom:0;background-color:#007ab3;border:2px solid transparent;border-radius:4px;color:#fff;cursor:pointer;font-size:16px;font-weight:600;letter-spacing:1px;line-height:24px;margin:0 0 24px;text-transform:uppercase;transition:background-color .2s ease,border-color .2s ease,color .2s ease,box-shadow .2s ease;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:auto;padding:10px 30px;-webkit-hyphens:inherit;-ms-hyphens:inherit;hyphens:inherit;word-break:break-word}
#center-tile-banner-popup button.accept-cookies-button:hover{background-color:#075994;border-color:#075994;color:#fff}
#center-tile-banner-popup .optanon-alert-box-wrapper .optanon-alert-box-notice{font-size:16px;line-height:24px;letter-spacing:0;margin:0 0 30px;font-family:"Allianz Neo",sans-serif;height:auto;text-align:center;margin-bottom:32px}
#optanon-cookie-policy{line-height:20px}
#optanon-cookie-policy .optanon-cookie-policy-group{margin-bottom:25px}
#optanon-cookie-policy .optanon-cookie-policy-group .optanon-cookie-policy-group-name{font-size:40px;line-height:48px;margin:0 0 50px;font-weight:300}
#optanon-cookie-policy .optanon-cookie-policy-group .optanon-cookie-policy-group-description{font-size:16px;line-height:24px;margin:0 0 30px}
#optanon-cookie-policy .optanon-cookie-policy-group .optanon-cookie-policy-cookies-used{font-size:26px;line-height:34px;margin:0 0 40px}
#optanon-cookie-policy .optanon-cookie-policy-group ul.optanon-cookie-policy-group-cookies-list{color:#414141;list-style:none;margin-bottom:30px;padding:0;counter-reset:list;font-size:18px;line-height:24px}
#optanon-cookie-policy .optanon-cookie-policy-group ul.optanon-cookie-policy-group-cookies-list li{margin-bottom:15px;padding-left:35px;position:relative;overflow-wrap:break-word}
#optanon-cookie-policy .optanon-cookie-policy-group ul.optanon-cookie-policy-group-cookies-list li:before{background-color:#bcd0d6;border-radius:50%;content:"";display:inline-block;height:10px;left:5px;margin-right:15px;position:absolute;top:7px;width:10px}
#optanon-cookie-policy .optanon-cookie-policy-group ul.optanon-cookie-policy-group-cookies-list li a{color:#006192;cursor:pointer;font-size:16px;font-weight:600;letter-spacing:1px;position:relative;text-decoration:none;transition:color .2s ease,box-shadow .2s ease;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;text-decoration:none !important}
#optanon #optanon-popup-top .optanon-close,.optanon-alert-box-wrapper .optanon-alert-box-corner-close a{background:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiM2MTYxNjEiIGQ9Ik0xOSw2LjRMMTcuNiw1TDEyLDEwLjZMNi40LDVMNSw2LjRsNS42LDUuNkw1LDE3LjZMNi40LDE5bDUuNi01LjZsNS42LDUuNmwxLjQtMS40TDEzLjQsMTJMMTksNi40eiIvPg0KPC9zdmc+");top:10px;right:10px}
.optanon-cookie-policy-subgroup-table{display:table;margin:0;padding:0;border-bottom:2px solid #5b5b5b;border-collapse:collapse;color:#414141;font-size:1.6rem;text-align:left;width:100%;line-height:22px;margin-bottom:30px}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-table-header{background:#f6f5f6;display:table-row;border:0}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-table-header p.optanon-cookie-policy-subgroup-table-column-header{margin:0 !important}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-table-header .optanon-cookie-policy-left,.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-table-header .optanon-cookie-policy-right{padding:15px 21px 15px 21px;vertical-align:top}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup{display:table-row;border:none !important;background-color:#fff}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup:nth-child(2n+1){background-color:#f5f5f5}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-left,.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-right{border:none !important;float:none !important;display:table-cell}
.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-name,.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-name,.optanon-cookie-policy-subgroup-table .optanon-cookie-policy-subgroup-cookies-list{margin:0 !important;padding:15px 21px 15px 21px;vertical-align:top}
.optanon-cookie-policy-subgroup-table ul{color:#414141;list-style:none;margin-bottom:30px;padding:0;counter-reset:list;font-size:18px;line-height:24px}
.optanon-cookie-policy-subgroup-table ul li{margin-bottom:15px;padding-left:35px;position:relative}
.optanon-cookie-policy-subgroup-table ul li:before{background-color:#bcd0d6;border-radius:50%;content:"";display:inline-block;height:10px;left:5px;margin-right:15px;position:absolute;top:7px;width:10px}
.optanon-cookie-policy-subgroup-table ul li a{color:#006192;cursor:pointer;font-size:16px;font-weight:600;letter-spacing:1px;position:relative;text-decoration:none;transition:color .2s ease,box-shadow .2s ease;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;text-decoration:none !important}
@media screen and (min-width:47em){#optanon #optanon-popup-wrapper{width:730px !important;margin:100px 0 0 -365px;right:auto}
}
@media screen and (max-width:745px){#optanon #optanon-popup-body-right{max-width:100%;width:auto;margin-top:0}
#optanon #optanon-popup-body div#optanon-main-info-text{max-height:100%}
#optanon #optanon-popup-body-left{max-width:100%;width:92% !important;margin:20px}
#optanon #optanon-menu{width:100%}
#optanon #optanon-popup-bottom-logo{display:none}
#optanon #optanon-popup-wrapper{left:15px;right:15px}
#optanon #optanon-popup-wrapper .optanon-button-wrapper{margin:0}
#optanon #optanon-popup-bottom{display:flex;flex-direction:column-reverse;align-items:center;padding:10px 0 10px 0}
}
@media screen and (max-width:500px){#optanon #optanon-popup-body div#optanon-main-info-text div.optanon-cookie-list span.optanon-subgroup-header{width:72%}
#optanon #optanon-popup-body-left{width:90% !important}
#optanon #optanon-popup-body h2{font-size:24px}
#optanon #optanon-popup-body h3{width:74%}
#optanon #optanon-popup-bottom .optanon-button-wrapper .optanon-save-settings-button .optanon-close .optanon-close-consent{margin-top:0}
#optanon #optanon-popup-bottom .optanon-button-wrapper .optanon-allow-all-button .optanon-allow-all{margin-top:0}
}
@media screen and (max-width:400px){#optanon #optanon-popup-body div#optanon-main-info-text div.optanon-cookie-list span.optanon-subgroup-header{width:65%}
#optanon #optanon-popup-body-left{width:88% !important}
#optanon #optanon-popup-body h2{font-size:20px}
#optanon #optanon-popup-body h3{width:70%}
}
#optanon .optanon-alert-box-logo{display:none}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle a:before,.optanon-alert-box-wrapper .optanon-alert-box-more-info-button button:before{background:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwMDYxOTIiIGQ9Ik0xNSwxN2MtMC4zLDAtMC41LTAuMS0wLjctMC4zYy0wLjQtMC40LTAuNC0xLDAtMS40bDIuMy0yLjNINmMtMC42LDAtMS0wLjQtMS0xczAuNC0xLDEtMWgxMC42bC0yLjMtMi4zDQoJYy0wLjQtMC40LTAuNC0xLDAtMS40czEtMC40LDEuNCwwbDQsNGMwLjIsMC4yLDAuMywwLjQsMC4zLDAuN2wwLDBjMCwwLjMtMC4xLDAuNS0wLjMsMC43bC00LDRDMTUuNSwxNi45LDE1LjMsMTcsMTUsMTd6Ii8+DQo8L3N2Zz4=") no-repeat right center;height:24px;width:24px;content:"";top:-5px;left:-25px}
.optanon-alert-box-wrapper .optanon-button-allow .optanon-alert-box-button-middle a:before{content:""}
.optanon-alert-box-wrapper .optanon-button-allow .optanon-alert-box-button-middle button:before,.optanon-alert-box-wrapper .optanon-alert-box-accept-button .optanon-alert-box-yes-i-accept button:before{content:""}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle a,.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button,.optanon-alert-box-wrapper .optanon-alert-box-more-info-button button{color:#006192 !important;text-transform:uppercase;font-weight:600;border:0;letter-spacing:1px}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button,.optanon-alert-box-wrapper .optanon-alert-box-more-info-button button{position:relative;text-decoration:none}
#center-tile-banner-popup .optanon-alert-box-more-info-button{width:auto}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle a:hover{color:#008ed6 !important}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle a:active{color:#003781 !important}
.optanon-alert-box-wrapper a{font-family:"Allianz Neo",sans-serif !important;color:#006192;font-weight:600;padding-left:3px;letter-spacing:1px}
.optanon-alert-box-wrapper .optanon-alert-box-body{margin-left:30px !important;margin-right:300px !important;padding-top:16px !important}
@media(max-width:703px){.optanon-alert-box-wrapper .optanon-alert-box-body{margin-right:auto !important}
}
.optanon-alert-box-wrapper .optanon-alert-box-bg p{line-height:22px;color:#414141 !important}
.optanon-alert-box-bg .optanon-alert-box-button-container{position:static !important;flex-direction:column;align-items:center;right:50px;top:40%}
#center-tile-banner-popup .optanon-alert-box-bg .optanon-alert-box-button-container{position:static}
#center-tile-banner-popup .optanon-alert-box-button-container .optanon-alert-box-accept-button{background:none !important;border:none !important}
.optanon-alert-box-wrapper .optanon-alert-box-button{margin:0 10px 0 0}
.optanon-alert-box-button .optanon-button-close .optanon-alert-box-button .optanon-button-more .optanon-toggle-display{padding-bottom:5px;line-height:1.4;color:#007ab3 !important}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle{padding:10px 10px 0 20px}
.optanon-alert-box-wrapper .optanon-alert-box-button-middle,.optanon-alert-box-accept-button{background-color:#007ab3 !important;border-color:#007ab3 !important;border-radius:5px;cursor:pointer;padding:0 1px !important;}
.optanon-alert-box-wrapper .optanon-alert-box-button-middle a,.optanon-alert-box-wrapper .optanon-alert-box-button-middle button{font-family:"Allianz Neo",sans-serif !important;color:#fff !important;text-transform:uppercase !important;padding:8px 18px;display:block}
.optanon-alert-box-accept-button .optanon-alert-box-yes-i-accept button{font-family:"Allianz Neo",sans-serif !important;color:#fff !important;text-transform:uppercase !important;padding:2px 12px;display:block}
.optanon-alert-box-wrapper .optanon-alert-box-button-middle button,.optanon-alert-box-wrapper .optanon-alert-box-accept-button .optanon-alert-box-yes-i-accept button{font-size:13px}
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-allow-all .optanon-alert-box-wrapper .optanon-alert-box-yes-i-accept .optanon-allow-all{font-family:"Allianz Neo",sans-serif !important;background-color:#007ab3 !important;color:#fff !important;text-transform:uppercase;cursor:pointer;font-weight:600;letter-spacing:.1rem;line-height:1.4}
#optanon #optanon-popup-top .optanon-close,.optanon-alert-box-wrapper .optanon-alert-box-corner-close{position:relative !important;bottom:0 !important;right:0 !important;top:0 !important;margin-top:10px !important;margin-right:15px !important;float:right}
#optanon #optanon-popup-top .optanon-close,.optanon-alert-box-wrapper .optanon-alert-box-corner-close a{top:0 !important;right:0 !important}
@media screen and (max-width:1024px){.optanon-alert-box-wrapper .optanon-alert-box-body{display:inline-block;width:100%;max-width:92%;padding-right:0;padding-top:0}
.optanon-alert-box-bg .optanon-alert-box-button-container{display:flex;flex-direction:row-reverse;justify-content:space-between;margin:0 40px 5px 30px}
.optanon-alert-box-wrapper .optanon-alert-box-button{margin-right:0}
.optanon-alert-box-bg .optanon-alert-box-button-container{right:0;position:relative}
#center-tile-banner-popup .optanon-alert-box-bg .optanon-alert-box-button-container{flex-direction:column}
#center-tile-banner-popup .optanon-alert-box-bg .optanon-alert-box-button-container{top:0 !important}
#center-tile-banner-popup .optanon-alert-box-wrapper .optanon-alert-box-body{display:block}
}
@media only screen and (max-width:47em){.optanon-alert-box-bg .optanon-alert-box-button-container{padding-right:0;margin-top:auto !important}
.optanon-alert-box-wrapper .optanon-alert-box-body{max-width:90%}
#center-tile-banner-popup{max-width:92%}
}
@media screen and (max-width:510px){.optanon-alert-box-bg .optanon-alert-box-button-container{flex-direction:column-reverse;align-items:center;margin:0 30px 0 30px}
.optanon-alert-box-button.optanon-button-allow .optanon-alert-box-button-container .optanon-alert-box-accept-button{margin-top:10px}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle{padding:10px 30px 0 15px}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle .optanon-toggle-display{margin-left:30px}
.optanon-alert-box-wrapper .optanon-alert-box-body{max-width:87%}
#center-tile-banner-popup .optanon-alert-box-bg .optanon-alert-box-button-container{top:25% !important}
}
.c-video--no-padding{padding-bottom:unset}
.c-video--no-padding .c-cookie{position:relative}
.optanon-alert-box-wrapper .optanon-alert-box-bg p{font-family:"Allianz Neo",sans-serif !important}
#optanon,#optanon *,#optanon div,#optanon span,#optanon ul,#optanon li,#optanon a,#optanon p,.optanon-alert-box-wrapper *{font-family:"Allianz Neo",sans-serif !important}
#center-tile-banner-popup .optanon-alert-box-body .optanon-alert-box-notice{max-width:100%;font-size:16px;margin-top:0;overflow-y:visible;line-height:24px;height:auto}
#center-tile-banner-popup .optanon-alert-box-body .optanon-alert-box-button-container{width:auto;top:55%}
.optanon-alert-box-wrapper .optanon-alert-box-yes-i-accept{padding-top:6px}
#optanon-popup-body-content{margin:10px 41px 0 42px !important}
#optanon-popup-body-content input{margin-right:5px}
@media(min-width:992px){#center-tile-banner-popup .optanon-alert-box-wrapper .optanon-alert-box-body{margin:auto !important;padding-top:15%}
}
@media(min-width:992px){#center-tile-banner-popup .optanon-alert-box-bg .optanon-alert-box-button-container{right:160px}
}
#optanon .fieldset .optanon-status-on input:checked+label{background-color:#007ab3;color:#006192}
#optanon .fieldset p input+label{hyphens:none}