@import "src/assets/scss/_variables.scss";
@import "src/assets/scss/_mixins.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
$main: ".main";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: rgb(245, 245, 245) !important;
  overflow-x: hidden !important;
  padding-top: 69px !important;
  color: $black-fade !important;

  #{$main} {
    padding: 3em 0em;

    &--whitebg {
      background: $white;
    }
  }

  @include small {
    padding-top: 60px;
  }
}

button {
  margin: 0px !important;
}

input {
  max-width: 100% !important;
}

p {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.punkte-einlosen--page {
  .main {
    .nx-grid--max-width {
      .nx-justify-content-large-start {
        width: auto;

        @include small {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .hero-section {
    &.hero-banner {
      @include small {
        min-height: 504px !important;
      }
    }
  }
}

.hero-section {
  &.hero-banner {
    .how-it-works--hero {
      p {
        @include small {
          font-size: 16px !important;
        }
      }
    }
  }
}

.advantage-section {
  background-color: $white;
  padding-top: 108px;
  padding-bottom: 64px;
  @include no-hyphens;

  @include small {
    padding: 40px 7px 0 7px;
  }

  @media only screen and (max-width: 320px) {
    padding: 40px 0px 0px 0px;
  }

  .text-center {
    margin-bottom: 40px;
  }

  .advantage-section-contant--heading {
    text-align: center;
    margin: auto;
    width: 928px;
    max-width: 100%;

    @include small {
      flex: 0 0 100%;
      max-width: 100%;
    }

    h2 {
      font-size: 40px;
      letter-spacing: 0.3px;
      line-height: 48px;
      margin-bottom: 52px;

      @include small {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 40px;
        @include no-hyphens;
      }
    }
  }

  .section-contant-one {
    position: relative;

    .left-block {
      position: absolute;
      float: left;
      width: 448px;
      margin-top: 48px;

      @include small {
        position: unset;
        margin-top: 15px;
      }

      h3 {
        width: 432px;
      }

      .body-text {
        width: 432px;
        height: 528px;

        @include small {
          height: 744px;
        }
      }

      p {
        letter-spacing: normal;
        margin-top: 0;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    img {
      width: 223px;
      margin: 27px 17px 28px 0;
      float: right;
      height: 124px;

      @include small {
        // padding-top: 36px;
        // width: 100% !important;
        margin: 0 !important;
      }
    }

    .boader-line-box {
      @include small {
        margin-bottom: 0 !important;

        .boader-line-six {
          height: 42px;
        }
      }
    }

    .right-block-image {
      float: right;

      @include small {
        padding-top: 58.72px;
        padding-bottom: 34.28px;
      }
    }
  }

  .section-contant-two {
    position: relative;

    .boader-line-three {
      height: 149px;

      @include small {
        height: 42px;
      }
    }

    .right-block-image {
      width: 305px;
      float: left;

      img {
        margin: 28px 0 28px 17px;
        width: 224px;
        float: left;
        height: 124px;

        @include small {
          width: 100%;
          margin: 0 !important;
          padding-top: 0px !important;
          height: auto;
        }
      }

      @include small {
        margin-top: 59px;
        margin-bottom: 34px;
      }
    }

    .left-block {
      width: 432px;
      float: right;
      height: 356px;
      position: absolute;
      top: 245px;
      right: 0;
      margin-top: 47px;

      @media only screen and (min-width: 704px) and (max-width: 1023px) {
        width: 399px;
      }

      @include small {
        position: unset;
        height: auto;
        margin-top: 16px;
      }

      h3 {
        width: 432px !important;
        max-width: 100%;
      }

      .body-text {
        width: 432px;

        @include small {
          height: 504px;
        }

        p {
          font-family: "Allianz Neo";
          letter-spacing: -0.1px;
          margin-bottom: 24px;
          margin-top: 0px;

          &:last-child {
            margin-bottom: 0;
          }

          @include small {
            max-width: 100%;
            height: auto;
            padding-right: 0;
            letter-spacing: 0;
            margin-bottom: 32px;
          }
        }
      }
    }

    .boader-line-two {
      height: 245px;

      @include small {
        right: 0;
        width: 90%;
        height: 42px;
      }
    }
  }

  .section-contant-three {
    .right-block-image {
      width: 305px;
      float: right;

      img {
        width: 223px;
        margin-right: 18px;
        float: right;
        margin-top: 7px;
        margin-left: auto;
        height: 123px;

        @include small {
          width: 100%;
          margin: 0 !important;
          padding-top: 0 !important;
        }
      }

      @include small {
        margin-top: 58.72px;
        margin-bottom: 38.28px;
      }
    }

    .left-block {
      width: 432px;
      float: left;

      @media only screen and (min-width: 704px) and (max-width: 1023px) {
        width: 399px;
      }

      p {
        @include small {
          letter-spacing: 0;
          line-height: 24px;
          height: auto;
          margin: 0;
          padding-right: 2px;
        }
      }

      @include small {
        margin-top: 16px;
      }
    }

    .boader-line-four,
    .boader-line-five {
      @include small {
        height: 42px;
        top: 0;
      }
    }

    .boader-line-box {
      // margin-bottom: 8.63px;
      .boader-line-four {
        height: 137px;

        @media only screen and (min-width: 704px) and (max-width: 768px) {
          height: 170px;
        }

        @include small {
          height: 42px;
        }
      }

      .boader-line-five {
        height: 61.37px;

        @include small {
          height: 42px;
        }
      }
    }
  }

  .section-contant-four {
    position: relative;

    .right-block-image {
      width: 305px;
      float: left;
      height: 180px;

      img {
        width: 224px;
        margin-top: 29px;
        margin-left: 16px;
        height: 124px;
        margin-bottom: 27px;

        @include small {
          width: 264px !important;
          margin: auto;
          margin-top: 69px;
          margin-bottom: 44px;
          height: 146px !important;
        }
      }

      @include small {
        text-align: center;
        height: auto;
      }
    }

    .left-block {
      width: 432px;
      float: right;
      position: absolute;
      right: 0;
      top: 62px;
      margin-top: 47px;

      @media only screen and (min-width: 704px) and (max-width: 1023px) {
        width: 399px;
      }

      @include small {
        margin-top: 16px;
        position: unset;
      }

      .body-text {
        height: 216px;

        @include small {
          height: auto;
        }

        p {
          margin-top: 0;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .boader-line-two {
      height: 62px;

      @include small {
        height: 42px;
      }
    }

    .boader-line-three {
      height: 173px;

      @include small {
        height: 42px;
      }
    }
  }

  .section-contant-five {
    @include small {
      padding-top: 0;
    }

    .boader-line-four {
      height: 173px;
      border-radius: 0 0 36px !important;

      @include small {
        height: 42px;
      }
    }

    .boader-line-five {
      height: 67px;

      @include small {
        height: 42px;
      }
    }

    .left-block {
      width: 432px;
      float: left;

      @media only screen and (min-width: 704px) and (max-width: 1023px) {
        width: 399px;
      }

      @include small {
        margin-top: 0;
      }
    }

    .boader-line-box {
      margin-bottom: 9px;

      @include small {
        margin-bottom: 16px;
      }
    }

    .body-text {
      p {
        margin: 0;
      }
    }

    .right-block-image {
      width: 305px;
      float: right;

      img {
        width: 224px;
        margin-right: 15px;
        float: right;
        height: 124px;

        @include small {
          width: 100%;
          margin: 0 !important;
          padding-top: 0 !important;
        }
      }

      @include small {
        margin-top: 58px;
        margin-bottom: 35px;
      }
    }
  }

  .advantage-section-contant {
    max-width: 100%;
    margin: auto;
    clear: both;
    width: 737px;

    @include max-medium {
      max-width: 100%;
    }

    .left-block {
      max-width: 100%;

      h3 {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0.2px;
        max-width: 100%;
        margin-bottom: 16px;

        @include small {
          font-size: 18px;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
      }
    }

    .right-block-image {
      max-width: 100%;

      @include small {
        width: 100%;
      }

      img {
        @media only screen and (max-width: 320px) {
          height: 158px;
          width: 285px;
          padding: 0;
        }
      }
    }

    .border-line {
      box-sizing: border-box;
      height: 42px;
      border-top: 2px solid #bc975e;
      border-right: 2px solid #bc975e;
      width: 80%;
      border-radius: 0 50px 0 0;

      @include small {
        width: 100%;
      }
    }

    .boader-line-two,
    .boader-line-four {
      position: relative;
      box-sizing: border-box;
      // height: 116px;
      max-width: 100%;
      border-right: 2px solid #bc975e;
      border-bottom: 2px solid #bc975e;
      border-radius: 0 0 32px;
      margin: auto;
      width: 198px;
      margin-right: 128px;

      @include small {
        width: 87%;
        margin-right: 0;
        height: 42px;
        right: 0;
      }
    }

    .boader-line-three,
    .boader-line-five {
      box-sizing: border-box;
      // height: 42px;
      width: 289px;
      max-width: 100%;
      border-left: 2px solid #bc975e;
      border-bottom: 2px solid #bc975e;
      border-radius: 0 0 0 35px;
      margin: auto;
      -webkit-transform: rotatex(180deg);
      transform: rotatex(180deg);
      margin-left: 127px;
      position: relative;
      margin-top: -2px;

      @include small {
        left: -67px;
      }

      + .right-block-image {
        text-align: unset;

        img {
          margin: 0;
        }
      }
    }

    .boader-line-five {
      @include small {
        width: 84%;
        margin-left: 0;
        left: 0 !important;
      }
    }

    .boader-line-three {
      @include small {
        left: -126px;
        width: 87%;
      }
    }

    .boader-line-six {
      box-sizing: border-box;
      height: 244px;
      width: 609px;
      max-width: 100%;
      float: right;
      border-left: 2px solid #bc975e;
      border-bottom: 2px solid #bc975e;
      border-radius: 0 0 0 30px;
      margin: auto;
      -webkit-transform: rotatex(180deg);
      transform: rotatex(180deg);

      @include small {
        top: 0;
        left: 0;
        width: 100%;
        transform: rotate(180deg);
      }
    }

    &.section-contant-one {
      .boader-line-box {
        @include small {
          transform: rotate(0deg);
        }
      }
    }

    .boader-line-box {
      transform: rotatey(180deg);

      // @include small {
      //   transform: rotatey(0deg);
      // }

      .boader-line-four {
        // height: 64px;
        border-radius: 0 0 35px;

        @include small {
          left: 0px !important;
          width: 87%;
        }
      }
    }

    &.responsive-content--change {
      @include small {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        .left-block {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }

        .right-block-image {
          -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
          order: 3;
        }
      }
    }

    &.section-contant-one,
    &.section-contant-two,
    &.section-contant-three,
    &.section-contant-five,
    &.section-contant-six,
    &.section-contant-seven,
    &.section-contant-eight {
      .right-block-image {
        img {
          @media only screen and (max-width: 320px) {
            height: 158px;
          }
        }
      }
    }

    &.section-contant-four {
      .right-block-image {
        img {
          @media only screen and (max-width: 320px) {
            width: 264px;
            height: 146px;
          }
        }
      }
    }

    &.section-contant-one,
    &.section-contant-three {
      .right-block-image {
        img {
          @media only screen and (max-width: 320px) {
            width: 285px;
          }
        }
      }
    }

    &.section-contant-two,
    &.section-contant-five,
    &.section-contant-six,
    &.section-contant-seven,
    &.section-contant-eight {
      .right-block-image {
        img {
          @media only screen and (max-width: 320px) {
            width: 286px;
          }
        }
      }
    }
  }
}

// New Block Styles

.top-question {
  clear: both;
  margin-top: 220px;
  background-color: $white;
  margin-bottom: 16px;

  .top-question--block-heading {
    font-size: 40px;
    line-height: 48px;
    font-weight: 300;
    letter-spacing: 0.3px;
    margin-bottom: 49px;

    @include small {
      margin-bottom: 35px;
      font-size: 35px;
      line-height: 40px;
    }
  }

  @include small {
    margin-top: 0;
  }

  .top-question--row {
    margin-bottom: 10px;

    @media only screen and (max-width: 799px) {
      margin-bottom: 0px;
    }

    &::before,
    &::after {
      display: table;
      content: " ";
      clear: both;
    }
  }

  .top-question--heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #414141;
    letter-spacing: 0.2px;
  }

  .top-question--desc {
    font-size: 16px;
    line-height: 24px;
    color: #414141;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    letter-spacing: 0.03px;

    @include small {
      margin-top: 8px;
    }
  }

  .top-question--link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #006192;
    padding-left: 4px;
    font-family: "Allianz Neo";
    letter-spacing: 1px;
    display: block;
    max-width: 176px;
    width: 100%;

    span {
      margin-left: -2px;
      max-width: 115px;
      width: 100%;
      display: inline-block;
    }

    &::before {
      content: "";
      background-image: url("../../assets/images/Asset\ 1.svg");
      height: 10px;
      width: 30px;
      background-repeat: no-repeat;
      display: inline-block;
    }
  }
}

.one-third {
  width: 100%;
}

.top-question-two {
  padding-left: 11px !important;

  @include small {
    padding-top: 9px !important;
    padding-left: 0 !important;
    margin-bottom: 10px !important;
  }
}

.top-question-three {
  padding-left: 21px !important;

  @include small {
    padding-left: 0 !important;
    margin-bottom: 25px !important;
  }
}

.top-question-last {
  padding-top: 0 !important;
  margin-top: -7px !important;

  @include small {
    padding-bottom: 0 !important;
  }
}

.column {
  padding: 15px 0;
  margin-bottom: 16px;

  .question--block {
    width: 352px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 800px) {
  .one-third {
    width: calc(100% / 3);
  }

  .column {
    float: left;
  }
}

.shopping-benefits--page {
  .hero-section {
    &.hero-banner {
      @include small {
        min-height: 448px !important;

        h1 {
          margin-bottom: 24px !important;
        }

        p {
          height: auto;
          line-height: 28px;
        }
      }

      .nx-grid--max-width {
        > div {
          @include small {
            height: auto;
          }
        }
      }
    }
  }
}

@include small {
  .padding-mb {
    padding: 0 !important;
  }
}

.nx-modal {
  z-index: 9999999 !important;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

// #cdk-overlay-0 {
//   transform: translateY(9.5px) !important;
//   min-width: 288px !important;
// }

app-how-it-works-page {
  .main--whitebg {
    &.main {
      padding-top: 0px;
      padding-bottom: 64px;

      @include small {
        padding-bottom: 40px;
      }
    }
  }

  .hero-section {
    .nx-grid--max-width {
      > div {
        height: 88px;

        p {
          height: auto;
        }
      }
    }
  }
}

@include small {
  .box-order {
    display: flex;
    flex-flow: column;

    .right-block-image {
      order: 2;
    }

    .left-block {
      order: 1;
    }
  }
}

.kart-icon {
  width: 149px;
}

.cart--img {
  @include small {
    position: relative;
  }

  .discount--round {
    display: none;

    @include small {
      display: block;
      position: absolute;
      background: $white;
      text-align: center;
      border-radius: 16px 16px 0px 16px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      font-size: 16px;
      font-weight: bold;
      color: $black-fade;
      bottom: 8px;
      right: 8px;
      width: 64px;
      height: 44px;
      line-height: 44px;
    }
  }
}

.advantage-section-contant {
  &.section-contant-six {
    padding-top: 21.39px;

    @include small {
      padding-top: 0;
    }

    .boader-line-two {
      height: 74.61px;

      @include small {
        height: 42px;
      }
    }

    .boader-line-three {
      height: 77px;

      @include small {
        height: 42px;
      }
    }

    .box-order {
      .left-block {
        height: 116px;
        margin-top: 107px;
        top: 96px;

        @include small {
          height: auto;
          margin-top: 16px;

          .body-text {
            height: auto;
          }
        }
      }

      .right-block-image {
        @include small {
          margin-top: 58px;
          margin-bottom: 31px;
        }
      }
    }
  }

  &.section-contant-seven {
    position: relative;

    .boader-line-box {
      margin-bottom: 0;
    }

    .boader-line-four {
      height: 64px;

      @include small {
        height: 42px;
      }
    }

    .right-block-image {
      img {
        margin-top: 15px;
        margin-bottom: 18.73px;
      }
    }

    .boader-line-five {
      height: 78px;

      @include small {
        height: 42px;
      }
    }

    .left-block {
      position: absolute;
      top: 64px;
      margin-top: 47px;

      @include small {
        position: unset;
        margin-top: 16px;
      }

      .body-text {
        @include small {
          padding-right: 7px;
        }

        p {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.section-contant-eight {
    .boader-line-two {
      height: 74.27px;

      @include small {
        height: 42px;
      }
    }

    .boader-line-three {
      height: 77px;

      @include small {
        height: 42px;
      }
    }

    .box-order {
      .left-block {
        margin-top: 12px;
        height: 156px;
        position: unset;

        @include small {
          height: auto;
          margin-top: 16px;
        }

        .body-text {
          @include small {
            height: auto;
          }

          p {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          a {
            &::before {
              content: "";
              background-image: url("../../assets/images/Asset\ 1.svg");
              height: 10px;
              width: 30px;
              background-repeat: no-repeat;
              display: inline-block;
            }
          }
        }
      }

      .right-block-image {
        @include small {
          margin-top: 50px;
          margin-bottom: 59px;
        }
      }
    }
  }
}

.body-text {
  max-width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  a {
    color: $allianz-blue;
    font-family: "Allianz Neo";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;

    span {
      max-width: 209px;
      width: 100%;
      display: inline-block;

      @include small {
        max-width: 254px;
      }
    }

    &.external--link {
      display: block;

      @include small {
        max-width: 286px;
        width: 100%;
      }
    }
  }
}

.faq-link--topblock {
  padding: 44px 0;
  box-shadow: inset 0 0 0 1px $light-grey;
  margin-top: 0;
  background-color: $white;

  @include max-medium {
    padding: 24px 16px;
  }

  p {
    max-width: 928px;
    width: 100%;
    color: $black-fade;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 0px auto;
    @include no-hyphens;

    @include max-medium {
      text-align: left;
    }
  }

  &.sticky {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;  
    right:0;
    z-index: 1;
    margin-top: 118px;
    transition: all 0.5s;

    // e

    @include small {
      margin-top: 109px;
    }
  }
}

.faq-link--bottomblock {
  padding: 40px 0 40px 0;

  @include max-medium {
    margin-top: 0px;
    padding: 64px 16px 24px 16px;
  }

  div.footer-center-text {
    max-width: 928px;
    width: 100%;
    color: $black-fade;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 0px auto;
    font-weight: 300;
    @include no-hyphens;
  }
}

#optanon-popup-bg {
  z-index: 7000 !important;

  &.overlay-popup-bg {
    display: block !important;
  }
}

.hotel-flap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $hotel-flap-color;
  color: $white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: 'Open Sans' !important;
  text-align: center;
  font-weight: bold;

  &.listing {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 7px;
    padding-bottom: 7px;

    @include small {
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 20px;
    }
  }

  &.detail {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 0;
    padding-top: 19px;
    padding-bottom: 19px;

    @include small {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 16px;
      line-height: 14px;
    }
  }

  &.checkout-success {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.14px;
    line-height: 20px;
  }

  &.checkout {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.14px;
    line-height: 20px;

    @include small {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-left: 16px;
      margin-right: 16px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
}